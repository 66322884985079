#root {
  .arduino-form {
    $form-help-icon-width: 12px;

    margin-bottom: 0;

    @include breakpoint-below($breakpoint-580) {
      padding-bottom: 110px;
    }

    .form-item-error {
      margin-bottom: 16px;
      margin-top: -7px;
      font-size: 12px;
      color: $notifyerror;
      font-style: normal;
      font-weight: 700;
      border-radius: 4px;
      background: rgb(223 115 101 / 20%);
      padding: 4px;
      width: 100%;
      text-align: center;
    }

    .form-group {
      display: flex;
      flex-direction: row;
      width: 100%;

      @include breakpoint-below($breakpoint-580) {
        flex-direction: column;
      }

      .form-item {
        min-width: ($layout-spacing-lg * 2);

        .reduce-width {
          width: calc(50% - 4px) !important;
        }
      }

      &.form-group-2-4-6 {
        .form-item {
          @include breakpoint-below($breakpoint-580) {
            flex-basis: 100%;
          }

          &:nth-child(1) {
            flex-basis: 20%;
          }

          &:nth-child(2) {
            flex-basis: 29.5%;
          }

          &:nth-child(3) {
            flex-basis: 50.5%;
          }
        }
      }

      &.form-group-6-6 {
        .form-item {
          @include breakpoint-below($breakpoint-580) {
            flex-basis: 100%;
          }

          &:first-child,
          &:last-child {
            flex-basis: 50%;
          }
        }
      }

      &.form-group-10-2 {
        .form-item {
          @include breakpoint-below($breakpoint-580) {
            flex-basis: 100%;
          }

          &:first-child {
            flex-basis: 70%;
          }

          &:last-child {
            flex-basis: 30%;
          }
        }
      }

      &.form-group-12-12 {
        flex-wrap: wrap;

        .form-item {
          flex-basis: 100%;

          &:last-child {
            margin-top: $layout-spacing-sm / 2;
          }

          & + .form-item {
            margin-left: 0;
          }
        }
      }

      .hide {
        display: none;
      }
    }

    .form-fields {
      align-items: center;
      border-radius: 2px;
      display: flex;
      flex-direction: column;
      padding: 0;
      text-align: left;

      @include breakpoint-below($breakpoint-580) {
        padding: 0;
      }
    }

    .form-label {
      color: $dust;
      font-size: 14px;
      height: 26px;
      letter-spacing: 0.7px;
      margin-bottom: 0;
      margin-top: $layout-spacing-lg - 35px;
      min-height: 26px;
      padding: 5px 0 0;
    }

    .form-helper-text {
      color: $onyx;
      font-size: 12px;
      letter-spacing: 0.7px;
      padding: 5px 0 0;
    }

    .form-item {
      flex: 1 2 100%;

      & + .form-item {
        @include breakpoint-above($breakpoint-580) {
          margin-left: 8px;
        }
      }

      input,
      select,
      textarea {
        line-height: $layout-spacing-sm;
        margin-bottom: 0;
        width: 100%;

        &.small-select {
          width: 300px;
        }
      }

      .ardu-radio {
        &.last-item {
          margin-bottom: 0;
        }
      }

      .error-message {
        @include font(opensans, regular);

        color: $notifyerror;
        font-size: 16px;
        letter-spacing: 0.7px;
        padding-top: 5px;
      }
    }

    sup {
      line-height: 0;
    }

    .popover-container {
      max-height: 24px;
    }

    .help-icon {
      @include size($form-help-icon-width);

      color: $teal2;
      cursor: pointer;
    }

    .button {
      margin-right: $layout-spacing-sm;
      margin-top: $layout-spacing-sm;
      width: 160px;

      &:last-child {
        margin-right: 0;
      }
    }

    .buttons {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      position: relative;

      .message-banner,
      >span {
        margin-right: auto;
        min-width: 50%;
      }
    }
  }
}
