#ino-header-new {
  .digital-store.brand-logo > svg {
    height: auto;
  }

  .digital-store.brand-logo {
    &:hover {
      cursor: default;
      background-color: transparent !important;
    }
  }
}
