@import "~arduino-sass/src/buttons";
@import "~arduino-sass/src/radiobutton";
@import "~arduino-sass/src/variables";
@import "~arduino-sass/src/spacing";
@import "../../../styles/mixins";
@import "../../../styles/variables";

#root {
  .checkout-maintenance {
    display: flex;
    align-items: center;
    flex-direction: column;

    img {
      margin-top: 100px;
    }

    h1 {
      color: $jet;
      font-size: 36px;
      line-height: 54px;
      font-weight: bold;
      margin-bottom: 0;
    }

    p {
      font-size: 20px;
      line-height: 34px;
      color: $jet;
      max-width: 500px;
    }

    hr {
      margin-top: 0;
      border-top: 2px solid $asbestos;
    }
  }
}
