@import "~arduino-sass/src/buttons";
@import "~arduino-sass/src/mixins";
@import "~arduino-sass/src/variables";
@import "../../../styles/mixins";
@import "../../../styles/variables";

#root .my-invoices-tab .billing-info-stack-card .side .payment-info-button {
  color: $white;
  font-weight: 300;

  &:hover {
    color: $white;
  }
}

#root .stack-card {
  // sass-lint:disable-line no-ids force-element-nesting
  $vertical-card-breakpoint: 1200px;

  background-color: $feather;
  border: 1px solid $fog;
  border-radius: 6px;
  display: flex;
  margin: 0 0 ($layout-spacing-sm);
  max-width: 100%;
  min-height: 200px;
  padding: $layout-spacing-sm $layout-spacing $layout-spacing;
  position: relative;
  text-align: left;
  width: 100%;

  @include breakpoint-below($vertical-card-breakpoint) {
    padding: $layout-spacing-sm;
  }

  @include breakpoint-below($vertical-card-breakpoint) {
    flex-direction: column;
  }

  &:last-child {
    margin-bottom: 0;
  }

  p {
    font-size: 20px;
    letter-spacing: 0.01em;
    line-height: 170%;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .main {
    flex: 1 0 auto;

    @include breakpoint-above($vertical-card-breakpoint) {
      padding-right: $layout-spacing;
      width: 66%;
    }

    @include breakpoint-below($vertical-card-breakpoint) {
      padding-bottom: $layout-spacing-xs;
    }
  }

  .side {
    align-items: center;
    display: flex;
    flex: 0 1 350px;
    flex-direction: column;
    justify-content: flex-start;

    @include breakpoint-below($vertical-card-breakpoint) {
      flex-basis: auto;
    }

    h4 {
      @include font(opensans, regular);

      margin-top: $layout-spacing-xs;
      text-transform: uppercase;
    }

    @include breakpoint-above($vertical-card-breakpoint) {
      border-left: 1px solid $fog;
      padding-left: $layout-spacing;
      width: 350px;
    }

    @include breakpoint-below($vertical-card-breakpoint) {
      border-top: 1px solid $fog;
      padding-top: $layout-spacing;
    }
  }

  .stack-card-title {
    margin-bottom: $layout-spacing;

    .transaction-title {
      font-size: 28px;
      line-height: 170%;
      letter-spacing: 0.01em;
      color: #434f54;
    }

    h3,
    h4 {
      letter-spacing: 1.6px;
      line-height: 38px;
      margin: 0;
    }

    h3 {
      font-size: 32px;
      margin-bottom: $layout-spacing-xs;
      text-transform: uppercase;
    }

    h4 {
      font-size: 20px;

      @include breakpoint-below($breakpoint-580) {
        font-size: 18px;
        line-height: 32px;
      }
    }

    .title {
      .plan-name {
        font-size: 28px;

        &__period {
          font-size: 20px;
          text-transform: lowercase;
        }
      }
    }

    .amount {
      font-size: 34px;

      &__period {
        font-size: 20px;
        text-transform: lowercase;
      }
    }

    @include breakpoint-below($breakpoint-768) {
      flex-direction: column;
      margin-bottom: $layout-spacing-lg;

      > div {
        margin-bottom: $layout-spacing-sm;
      }

      h4 {
        margin-top: $layout-spacing-xs;
      }
    }
  }

  .stack-card-action-button,
  .stack-card-action-button-danger {
    display: block;
    max-width: 320px;
    //width: 100%;

    font-size &:last-child {
      margin-bottom: 0;
    }
  }

  .stack-card-action-button {
    @include ardu-store-button($teal3, $white);
    @include font(opensans, regular);

    font-weight: bold !important;
    line-height: normal !important;
    font-size: 14px !important;

    &.downgrade-button {
      @include ardu-store-button($teal3, $white);
    }

    &.custom-button {
      @include ardu-store-button($teal3, $white);

      margin-top: 12px;
    }

    &,
    &.downgrade-button {
      font-size: 18px;
      letter-spacing: 1.14px;
      line-height: 170%;
      margin-bottom: $layout-spacing-sm;
    }
  }

  .stack-card-action-button-danger {
    @include ardu-store-button($feather, $feather);
    @include font(opensans, bold);

    color: $teal3;
    font-size: 13px;
    letter-spacing: 1.39px;
    line-height: 18px;
  }

  .subscription-button-loader {
    display: flex;
    height: 37px;
    justify-content: center;
    margin-bottom: $layout-spacing-sm;
    position: relative;
    width: 100%;

    .spinner {
      display: block;

      $spinner-color: $teal2;
      $spinner-size: 37px;

      @include spinner($spinner-color, $feather, $spinner-size);

      &.white {
        @include spinner($spinner-color, $white, $spinner-size);
      }
    }
  }

  pre {
    max-width: ($layout-spacing-lg * 2);
  }

  &.sideless {
    .main {
      padding-right: 0;
    }
  }
}
