@import "~arduino-sass/src/mixins";
@import "~arduino-sass/src/variables";
@import "../../../styles/variables";
@import "../../../styles/mixins";

#root {
  .subscription-title {
    font-size: 28px;
    font-weight: 400;
    color: $jet;
    line-height: 170%;
    letter-spacing: 0.01em;

    .subscription-email {
      font-size: 16px;
      line-height: 170%;
      letter-spacing: 0.01em;
      color: $jet;
    }

    .subscription-icon {
      vertical-align: middle;
      margin-left: 5px;

      svg {
        margin-top: 15px;
      }
    }

    .subscription-coupon {
      margin-left: 5px;
    }
  }

  .subscription-time {
    margin-left: 8px;
    padding: 5px 8px;
    color: $black;
    background-color: #f1c40f;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Roboto Mono", monospace;
    letter-spacing: 0.1em;
    line-height: 1;
  }

  .admins {
    color: #4e5b61;
    font-size: 14px;

    &--text {
      margin: 12px 0;
    }

    &--list {
      margin-bottom: 8px;
    }
  }

  .expiration-date {
    width: 100%;
    padding: 12px 14px;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    color: $dust;
    margin-bottom: 90px;

    @include breakpoint-below($breakpoint-1200) {
      margin-bottom: 24px;
    }
  }
}
