/* stylelint-disable */
@import '~arduino-sass/src/buttons';
@import '~arduino-sass/src/typography';
@import '~arduino-sass/src/variables';
@import '../../../../styles/variables';

#root {
  .extend-button {
    color: white;
    background-color: $teal3;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    border: 1px solid $teal3;
    border-radius: 500px;
    padding: 9px 18px;
    letter-spacing: 0.01em;
    text-align: center;
  }
}

.extend-img {
  object-fit: cover;
  width: 100%;
  max-height: 180px;
}

.extend-modal {
  line-height: 170%;
  letter-spacing: 0.01em;
  .modal-module_modal__1MUEa .modal-module_content__3jijf.modal-module_wide__1WsUE {
    padding: 5px !important;
  }

  &__content {
    margin-top: 24px;
    padding: 0 80px;
  }

  &__button {
    display: flex;
    justify-content: flex-end;
  }

  p {
    font-weight: 400;
    font-size: 16px;
  }
}
