/**
 * Index file for the application root.
 * Edit this file to:
 *   - import npm dependencies
 *   - import mixins, variables, and global scope scss files
 *   - write theme-specific css
 *   - style html, body and #root
 * To style single components, you should create a .scss file alongside a component
 */

@import "~arduino-sass/src/variables";
@import "~arduino-sass/src/typography";
@import "~arduino-sass/src/breakpoints";
@import "~arduino-sass/src/utils";
@import "variables";
@import "mixins";
@import "app-bar";
@import "common";
@import "form";
@import "landing";

html {
  background-color: $clouds;
  box-sizing: border-box;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //overflow: hidden;

  @include breakpoint-below($breakpoint-1200) {
    overflow: auto;
  }
}

* {
  box-sizing: inherit;

  &::before,
  &::after {
    box-sizing: inherit;
  }
}

img,
a {
  border: 0; // fix for IE10
}

body {
  @include font(opensans, regular);

  background-color: $clouds;
  color: $black;
  font-size: 16px;
  height: 100%;
  margin: 0;
  min-height: 100%;
  padding: 0;
}

.page-title-h1 {
  text-transform: uppercase;
}

.white-background {
  background-color: $white;
  border: 1px solid rgba($fog, 0.7);
  padding: 30px;
}

#create-header {
  height: $app-bar-height;
}

#ino-header-new .platform-navigation {
  z-index: 45 !important;
}
