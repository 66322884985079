/* stylelint-disable */
@import '~arduino-sass/src/buttons';
@import '~arduino-sass/src/mixins';
@import '~arduino-sass/src/variables';
@import '~arduino-sass/src/checkbox';
@import '~arduino-sass/src/inputfield';
@import '~arduino-sass/src/radiobutton';
@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.aws-list {
  width: 100%;

  &__title {
    font-weight: 400;
    font-size: 28px;
    line-height: 170%;
    color: $jet;
    margin-bottom: 28px;
    text-align: left;
    display: flex;
    align-items: center;
  }

  &__card {
    padding: 42px;
    border-radius: 4px;
    background-color: $feather;
    border: 1px solid $silver;
    margin-bottom: 40px;
    display: flex;

    @include breakpoint-below($breakpoint-768) {
      flex-direction: column;
    }

    &--maker {
      border-left: 8px solid $teal2;
    }

    &--pro {
      border-left: 8px solid $jet;
    }
  }

  &__box {
    font-family: 'Open Sans';
    letter-spacing: 0.01em;
    margin: 5px 10px 5px 0px;
    padding: 16px;
    background-color: #fff;
    border: 1px solid #c9d2d2;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    color: #434f54;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__plan-details {
    margin-bottom: 28px;
  }

  &__sub-title {
    font-weight: 700 !important;
    font-size: 12px;
    line-height: 170%;
    color: #434f54;
    margin-top: 0;
    margin-bottom: 8px;
    letter-spacing: 0.01em;
  }

  &__content {
    flex: 0 0 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    text-align: left;

    @include breakpoint-below($breakpoint-768) {
      flex-direction: column;
      border-right: none;
    }
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
  }

  &__info {
    padding: 12px;
    border-radius: 3px;
    border: 1px solid $silver;
    background: $white;
  }
}