@import "~arduino-sass/src/variables";
@import "~arduino-sass/src/switch";
@import "../../styles/mixins";
@import "../../styles/variables";

#root .subscription-create-card {
  // sass-lint:disable-line no-ids force-element-nesting

  .pending-change {
    @include pending-banner();
  }

  .new-pending-change {
    width: 100%;
    padding: 8px 12px;
    background-color: #fcfeee;
    border: 1px solid #f1c40f;
    border-radius: 3px;

    .banner-row {
      display: flex;
    }

    .banner-description {
      margin-left: 12px;
    }

    .link-button {
      margin: 12px 0 0 24px !important;
    }
  }

  .coupon-banner {
    margin-top: 48px;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
    padding: 12px;
  }

  .past-due-alert {
    display: flex;
    background-color: #f9e9e6;
    border: 1px solid #da5b4a;
    border-radius: 3px;
    padding: 7px 12px 8px;
    margin: 12px 0;

    .text-column {
      display: flex;
      flex-direction: column;
      margin: 0 8px;
    }

    .icon-column {
      margin: 0;
    }
  }
}

.payment-info-description {
  text-align: center;
}

.edu {
  border-left: 5px solid #f39c12 !important;
}

.pro {
  border-left: 5px solid #000 !important;
}

.maker {
  border-left: 5px solid #008184 !important;
}
