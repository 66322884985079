@import "~arduino-sass/src/buttons";
@import "~arduino-sass/src/typography";
@import "mixins";
@import "variables";

#root .landing-page { // sass-lint:disable-line no-ids force-element-nesting
  color: $black;
  display: flex;
  font-size: $landing-page-font-size;
  justify-content: center;
  letter-spacing: .01em;
  position: relative;

  .landing-page-container {
    background: $white;
    width: 100vw;
  }

  p {
    font-size: $landing-page-font-size;
    letter-spacing: .01em;
    line-height: 170%;
  }

  h2 {
    font-size: 38px;
    line-height: 40px;
    margin-top: 0;

    em {
      color: $gold;
      font-style: normal;
    }

    @include breakpoint-below($breakpoint-580) {
      hyphens: auto;
      word-break: break-word;
    }
  }

  .landing-columns {
    text-align: left;

    @include breakpoint-above($breakpoint-980) {
      break-inside: avoid-column;
      column-count: 2;
      column-gap: 10%;
      column-rule: 1px solid rgba($gold, .5);
    }

    @include breakpoint-above($breakpoint-1200) {
      column-gap: 20%;
    }
  }

  .landing-column-element {
    break-inside: avoid;
    margin-bottom: ($layout-spacing * 2);
    padding-top: 0;

    @include breakpoint-below($breakpoint-580) {
      margin-bottom: ($layout-spacing-lg + $layout-spacing-xs);
    }

    a {
      @extend %text-link;
    }
  }

  .jumbotron {
    display: flex;
    height: 70vh;
    justify-content: center;
    max-height: 800px;
    min-height: 650px;
    width: 100%;

    @include breakpoint-below($breakpoint-980) {
      background: $sunflower;
      background-image: none !important;
      padding-bottom: 0;

      .landing-layout-box:nth-child(1) {
        display: none;
      }

      .landing-layout-box:nth-child(2) {
        padding-top: 25px;
      }
    }

    @include breakpoint-below($breakpoint-580) {
      height: 80vh;
    }

    @include breakpoint-below(335px) {
      height: auto;
      max-height: 100vh;
    }

    .landing-page-wrapper {
      display: flex;
      flex-direction: row-reverse;
      max-width: $page-max-width;
      width: 100%;
    }
  }

  .jumbotron-right {
    position: relative;

    @include breakpoint-below($breakpoint-980) {
      flex: 0 1 100%;
    }

    .jumbotron-title {
      @include font(roboto-mono, bold);

      color: $black;
      font-size: 46px;
      letter-spacing: 8.5px;
      margin: 0;
      text-align: left;
      text-transform: uppercase;

      @include breakpoint-below(1230px) {
        font-size: 36px;
        hyphens: auto;
        word-break: break-word;
      }

      em {
        color: $darknotifyerror;
        font-size: .8em;
        font-style: normal;
      }
    }

    .jumbotron-description {
      color: $black;
      letter-spacing: .7px;
      line-height: 38px;
      margin-bottom: $layout-spacing;
      text-align: left;

      @include breakpoint-between($breakpoint-980, $breakpoint-980) {
        font-size: 26px;
      }

      @include breakpoint-below($breakpoint-580) {
        font-size: 20px;
        line-height: 20px;
      }
    }

    .jumbotron-category {
      @include font(roboto-mono, bold);

      border-bottom: 1px solid rgba($black, .5);
      color: $black;
      font-size: 16px;
      letter-spacing: 2.5px;
      line-height: 30px;
      text-align: left;
      text-transform: uppercase;
    }

    h4 {
      @include font(opensans, regular);

      color: $black;
      font-size: 20px;
      letter-spacing: 2px;
      text-align: center;

      @include breakpoint-below($breakpoint-980) {
        letter-spacing: .7px;
      }

      @include breakpoint-between($breakpoint-980, 1100px) {
        font-size: 19px;
      }

      @include breakpoint-below($breakpoint-580) {
        font-size: 19px;
      }
    }

    .region-block-disclaimer {
      @include font(opensans, regular);

      font-size: 15px;
      position: absolute;
      text-align: center;
      width: 100%;
    }
  }

  .landing-layout-box {
    &.lightbronze {
      background-color: $lightbronze;

      h2 {
        color: $white;
      }

      .link {
        color: $teal4;

        &:hover {
          color: $teal5;
        }
      }
    }

    &.sunflower {
      background-color: $sunflower;

      h2 {
        color: $white;
      }

      .link {
        &:hover {
          color: $teal4;
        }
      }
    }

    .landing-layout-box-content {
      position: relative;
    }

    .link {
      @extend %text-link;

      font-weight: bold;
    }

    .carrot-button,
    .teal-button {
      @include breakpoint-below($breakpoint-580) {
        width: 100%;
      }

      &.lg {
        font-size: 25px;
      }
    }

    .carrot-button {
      @include ardu-store-button($carrot, $white);

      font-size: 18px;
      letter-spacing: 1.46px;
      margin: 20px 0;
    }

    .teal-button {
      @include ardu-store-button($teal1, $white);

      font-size: 18px;
      letter-spacing: 1.46px;
      margin: 20px 0;
    }

    .small-text {
      font-size: 14px;
      letter-spacing: .4px;
      line-height: 20px;
    }
  }

  .landing-layout-40-40 {
    display: flex;
    text-align: left;

    @include breakpoint-below($breakpoint-980) {
      flex-wrap: wrap;
    }

    .landing-layout-box {
      align-items: center;
      display: flex;
      flex: 0 0 50%;
      max-width: 50%;
      padding: ($layout-spacing-lg * 2) 0;
      position: relative;

      @include breakpoint-below($breakpoint-980) {
        flex-basis: 100%;
        max-width: 100%;
      }

      .landing-page-wrapper {
        max-width: $page-max-width / 2;
        width: 100%;

        @include breakpoint-below($breakpoint-980) {
          max-width: 100%;
        }
      }

      &:nth-child(1) {
        justify-content: flex-end;

        @include breakpoint-below($breakpoint-980) {
          justify-content: center;
        }

        .landing-page-wrapper {
          padding-left: $layout-spacing * 2;

          @include breakpoint-below($breakpoint-980) {
            padding: 0 $layout-spacing * 2;
          }

          @include breakpoint-below($breakpoint-580) {
            padding: 0 $layout-spacing;
          }

          .landing-layout-box-content {
            padding-right: 20%;

            @include breakpoint-below($breakpoint-1200) {
              padding-right: 10%;
            }

            @include breakpoint-below($breakpoint-980) {
              padding-right: 0;
            }
          }
        }
      }

      &:nth-child(2) {
        justify-content: flex-start;
        min-height: 527px;

        .landing-page-wrapper {
          padding-right: $layout-spacing * 2;

          @include breakpoint-below($breakpoint-980) {
            padding: 0 $layout-spacing * 2;
          }

          @include breakpoint-below($breakpoint-580) {
            padding: 0 $layout-spacing;
          }

          .landing-layout-box-content {
            padding-left: 20%;

            @include breakpoint-below($breakpoint-1200) {
              padding-left: 10%;
            }

            @include breakpoint-below($breakpoint-980) {
              padding-left: 0;
            }
          }
        }
      }
    }
  }

  .landing-layout-33-33-33 {
    padding: ($layout-spacing-lg * 2) $layout-spacing-lg;

    .landing-page-wrapper {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    @include breakpoint-below($breakpoint-980) {
      flex-direction: column;
    }

    .landing-layout-content {
      display: flex;
      justify-content: space-around;
      max-width: $page-max-width;
      text-align: left;
      width: 100%;

      @include breakpoint-below($breakpoint-980) {
        align-items: center;
        flex-direction: column;
      }
    }

    .landing-layout-box {
      display: flex;
      flex: 1 1 calc(33.333% - #{4 * $layout-spacing / 3});
      max-width: calc(33.333% - #{4 * $layout-spacing / 3});
      min-height: 200px;
      padding: $layout-spacing-sm $layout-spacing;

      @include breakpoint-below($breakpoint-980) {
        flex: 0 1 100%;
        max-width: 100%;
        padding: $layout-spacing-lg 0;
      }
    }
  }

  .landing-layout-100 {
    display: flex;
    justify-content: center;

    h3 {
      text-align: center;
    }

    .landing-page-wrapper {
      display: flex;
      justify-content: space-between;
      max-width: $page-max-width;
      text-align: left;
    }

    .landing-layout-box {
      padding: ($layout-spacing-lg * 2) $layout-spacing-lg;

      .landing-layout-box-content {
        padding: 0 ($layout-spacing * 2);

        @include breakpoint-below($breakpoint-980) {
          width: 100%;
        }

        @include breakpoint-below($breakpoint-580) {
          padding: $layout-spacing;
        }

        h3 {
          margin-bottom: $layout-spacing-lg * 2;
        }

        .description {
          font-size: $landing-page-font-size;
        }
      }
    }
  }

  .landing-layout-20-60-20 {
    display: flex;
    justify-content: center;

    .landing-page-wrapper {
      align-items: center;
      display: flex;
      justify-content: center;
      max-width: $page-max-width;
      text-align: left;
    }

    .landing-layout-box {
      display: flex;
      justify-content: center;
      padding: ($layout-spacing-lg * 2) $layout-spacing-lg;
      width: 100%;

      &.lightbronze {
        background-color: $lightbronze;

        h2 {
          color: $white;
        }
      }

      &.sunflower {
        background-color: $sunflower;

        h2 {
          color: $white;
        }
      }

      .landing-layout-box-content {
        flex: 0 1 60%;
        padding: 0 ($layout-spacing * 2);

        @include breakpoint-below($breakpoint-980) {
          padding-right: 0;
          width: 100%;
        }

        @include breakpoint-below($breakpoint-580) {
          padding: 0;
        }

        h3 {
          margin-bottom: $layout-spacing-lg;
        }

        .description {
          font-size: $landing-page-font-size;
        }
      }
    }
  }

  .landing-layout-100,
  .landing-layout-33-33-33,
  .landing-layout-20-60-20,
  .landing-layout-40-40 {
    .landing-layout-box {
      height: auto;
      min-height: calc(80vh - 112px);
      padding: ($layout-spacing-lg * 2) 0;
      position: relative;
    }
  }

  .activate-bottom {
    background: $basegray;
    bottom: 0;
    box-shadow: 0 -4px 0 0 rgba($black, .07);
    padding: $layout-spacing-xs 0;
    position: sticky;
    transform: translateY(64px);
    transition: transform .2s linear;
    z-index: 10;

    .activate-bottom-button {
      @include ardu-store-button($carrot, $white);

      font-size: $font-size-md;
    }

    &.show {
      transform: translateY(0);
    }
  }
}
