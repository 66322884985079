@import "~arduino-sass/src/buttons";
@import "~arduino-sass/src/variables";
@import "../../../styles/mixins";
@import "../../../styles/variables";

#root .purchase {
  // sass-lint:disable-line no-ids force-element-nesting
  background: linear-gradient(to right, $clouds 0%, $clouds 50%, #000 50%, $white 50%, $white 100%);
  display: flex;
  justify-content: center;

  .absolute-spinner-container {
    background-color: $clouds;
    bottom: 0;
    height: 100%;
    z-index: 1;
  }

  .sticky-outer-wrapper {
    width: 100%;
    height: 100%;

    .sticky-inner-wrapper {
      height: 100%;
    }
  }

  .form-name-container {
    width: 100%;

    .title {
      text-align: left;
      font-size: 36px;
      line-height: 150%;
      font-weight: normal;
      margin-bottom: 8px;
    }

    .sub-title {
      text-align: left;
      margin-bottom: 24px;
      line-height: 24px;
    }
  }

  .form-button {
    display: flex;
    font-weight: bold;
    color: $teal3;
    font-size: 16px;
    margin-bottom: 25px;
  }

  .hidden {
    display: none;
  }

  .product-section {
    display: flex;
    max-width: $page-max-width;
    min-height: calc(100vh - 61px);
    position: relative;
    width: calc(100% - 70px);

    @include breakpoint-below($breakpoint-1200) {
      flex-direction: column;
      width: 100%;
    }

    .form-section {
      background-color: $clouds;
      display: flex;
      justify-content: center;
      min-height: 90vh;
      padding: 40px (2 * $layout-spacing) $layout-spacing-lg $layout-spacing;
      width: 60%;

      @include breakpoint-below($breakpoint-1200) {
        flex-basis: auto;
        min-height: initial;
        padding-bottom: $layout-spacing-lg;
        padding-right: $layout-spacing;
        width: 100%;
      }

      @include breakpoint-below($breakpoint-580) {
        padding: $layout-spacing-sm $layout-spacing-sm $layout-spacing-lg;
      }

      h3 {
        @include font(opensans, regular);

        margin-top: 0;
        text-align: left;
      }

      .breadcrumbs {
        font-size: 16px;
        height: $layout-spacing-sm;
        letter-spacing: 0.7px;
        margin-bottom: $layout-spacing-sm;
        margin-top: 0;

        ul {
          display: flex;
          margin: 0;
        }

        .active {
          @include font(opensans, regular);

          color: $teal2;
          font-weight: bold !important;
        }

        li {
          margin-right: $layout-spacing-xs;
        }
      }
    }

    .arduino-form {
      @include breakpoint-below($breakpoint-1200) {
        padding-bottom: 0;
      }
    }

    .details-section {
      background-color: $white;
      display: flex;
      justify-content: center;
      width: 40%;
      padding: 40px $layout-spacing 0;

      @include breakpoint-below($breakpoint-1200) {
        flex: 1 1 auto;
        left: 0;
        padding-bottom: $layout-spacing-lg;
        position: relative;
        width: 100%;
      }

      @include breakpoint-below($breakpoint-580) {
        padding: $layout-spacing-sm $layout-spacing-sm 0;
      }

      .details-section-container {
        flex: 1 1 100%;
      }
    }
  }

  .delete-card-modal {
    .modal-body {
      h3 {
        padding: 0;
      }
    }
  }

  .view-button-container {
    margin-top: 50px;
    text-align: end;

    .view-button {
      @include ardu-store-button($carrot, $white);

      font-size: $font-size-md;
    }
  }

  .change-billing-button {
    font-size: 16px;
    font-weight: 500;
    margin-top: 20px;
    text-transform: uppercase;
  }

  .billing-view {
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 0.01em;
    line-height: 170%;
  }

  .billing-wrapper {
    background-color: white;
    border: 1px solid #3fae98;
    border-radius: 4px;
  }
}
