@import "~arduino-sass/src/buttons";
@import "~arduino-sass/src/radiobutton";
@import "~arduino-sass/src/variables";
@import "~arduino-sass/src/spacing";
@import "../../../styles/mixins";
@import "../../../styles/variables";

#root {
  .form-fields {
    .plan {
      display: flex;
      background: white;
      border-radius: $space-xx-small;
      padding: 24px $space-medium $space-medium;
      flex-direction: column;
      width: 100%;
      margin-bottom: 24px;

      &__frequency {
        margin-bottom: 16px;
      }

      &__org-name {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        svg {
          margin-right: 12px;
        }
      }

      .card {
        background-color: $feather;
        border: 1px solid $feather;
        color: $jet;
        border-radius: 6px;
        margin: 8px;
        width: 100%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        cursor: pointer;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        @include breakpoint-below($breakpoint-580) {
          margin-left: 0;
        }

        &-title {
          font-size: 16px;
          margin-bottom: 8px;
        }

        &-box {
          font-family: "Roboto Mono", monospace;
          font-size: 12px;
          line-height: 1;
          font-weight: 700;
          text-transform: uppercase;
          background-color: #7fcbcd;
          color: $black;
          padding: 5px 8px;
          border-radius: 6px;
          letter-spacing: 0.1em;
        }

        &-selected {
          background-color: #e8f5f3;
          border: 1px solid $teal3;
        }

        .right {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          &__left {
            display: flex;
            flex-direction: column;
          }

          &__right {
            display: flex;
          }
        }

        &:hover {
          border: 1px solid $teal3;
        }
      }

      &-title {
        font-size: $space-medium;
        color: $dust;
        line-height: 170%;
        letter-spacing: 0.01em;
        margin-bottom: 4px !important;
      }
    }

    .contacts {
      display: flex;
      width: 100%;
      justify-content: flex-start;

      &-link {
        padding: 0 4px;
        text-align: left;
        font-weight: 700;
        color: $teal3;
      }
    }

    .submit-button,
    .cancel-button {
      @include ardu-store-button($teal3, $white);

      margin-left: $space-large;
      margin-top: $layout-spacing-lg;

      @include breakpoint-below($breakpoint-580) {
        margin-left: 0;
      }
    }

    .disabled {
      opacity: 0.5 !important;
    }
  }
}
