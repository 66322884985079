@import "~arduino-sass/src/buttons";
@import "~arduino-sass/src/typography";
@import "~arduino-sass/src/variables";
@import "../../../../styles/variables";

#root {
  .delete-thing {
    padding: 16px 90px 36px !important;
    overflow-x: hidden;
  }

  .heading {
    text-align: center;
    width: 875px !important;
    margin-left: -90px;
    border-bottom: 1px solid $smoke;
    padding-bottom: 13px;
  }

  hr.custom-hr {
    border-top: 1px solid $smoke;
    margin: 10px -100px;
  }

  .description {
    width: 100%;
    margin: 24px 0;
  }

  .argument {
    color: #000;
    font-size: 16px;
    line-height: 170%;
    letter-spacing: 0.01em;

    &--first {
      margin-bottom: 32px;
    }

    &--second {
      margin-bottom: 24px;
    }
  }

  .buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center !important;
  }

  hr {
    width: 100% !important;
  }

  .textarea {
    width: 100%;

    &:focus {
      border: 1px solid #3fae98;
    }

    &-label {
      color: #7f8c8d;
      font-size: 12px;
    }
  }

  .feature-list {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    letter-spacing: 0.01em;
    color: #000;

    .feature {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      &--text {
        margin-left: 12px;
      }
    }
  }
}
