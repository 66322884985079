/* stylelint-disable */
@import '~arduino-sass/src/buttons';
@import '~arduino-sass/src/mixins';
@import '~arduino-sass/src/variables';
@import '~arduino-sass/src/checkbox';
@import '~arduino-sass/src/inputfield';
@import '~arduino-sass/src/radiobutton';
@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.modal {
  padding: 0 40px;

  &__buttons {
    margin: 28px 0;
    display: flex;
    justify-content: flex-end;
  }

  &__link {
    color: $teal3;
    font-weight: 700;
  }

  &__description {
    margin-bottom: 28px;
  }

  p {
    font-size: 12px;
  }

  &__loader {
    display: flex;
    justify-content: center;
  }

  &__img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
  }
}

.organization {
  &--disabled {
    color: #95a5a6 !important;
    background-color: $feather !important;
    border-color: 1px solid $silver !important;
    cursor: unset;
  }

  &--not-creable {
    color: #00818480 !important;
    background-color: $feather !important;
    border-color: 1px solid $silver !important;
    cursor: unset;
  }

  &__not-compatible {
    font-family: 'Roboto Mono';
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.1em;
    color: $gris;
    padding: 4px;
    border: 1px solid $gris;
    margin-left: 16px;
  }

  &__popover {
    width: 150px;
    font-weight: 400;
    font-size: 11px;
  }

  &__wrapper {
    border: 1px solid $silver;
    border-radius: 4px;
    background-color: white;
    padding: 16px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    &--right {
      margin-left: 8px;
    }

    &--reverse {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
  }

  &__disabled {
    .organization {

      &__wrapper {
        background-color: $feather;
      }

      &__title {
        color: #95A5A6;
      }

      &__members {
        color: #95A5A6;
      }
    }

    &:hover {
      cursor: unset;
    }
  }

  &__create {
    color: $teal3;
    font-weight: 700;
  }

  &__title {
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 400;
  }

  &__members {
    font-size: 12px;
    color: $jet;
  }
}


.ardu-radio {
  @extend %ardu-radio;
  cursor: pointer;
}


.select-custom-margin {
  width: 100%;
}

.enterprise-reedeem {
  max-height: 80vh;
}