@import "~arduino-sass/src/variables";
@import "~arduino-sass/src/mixins";
@import "../../../../styles/mixins";
@import "../../../../styles/variables";

.maker-plan-redeem-modal {
  padding: 0 !important;

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $feather;

    img {
      padding: 20px;
    }
  }

  &__content {
    padding: 0 !important;
  }

  &__description {
    padding: 28px 130px 36px;

    @include breakpoint-below($breakpoint-768) {
      padding: 28px;
    }
  }

  /* stylelint-disable */
  &__buttons {
    background-color: $feather;
    padding: 20px 130px;
    display: flex;
    justify-content: flex-end;

    @include breakpoint-below($breakpoint-768) {
      padding: 20px;
    }
  }
}
