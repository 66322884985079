/* stylelint-disable */
@import '~arduino-sass/src/buttons';
@import '~arduino-sass/src/typography';
@import '~arduino-sass/src/variables';
@import '../../../styles/mixins';
@import '../../../styles/variables';

#root .arduino-store-modal-overlay {
  // sass-lint:disable-line no-ids force-element-nesting
  align-items: center;
  background-color: rgba($black, 0.6);
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  transition: background-color 0.3s ease-in-out, opacity 0.3s ease-in-out;
  z-index: 1005;

  &.ReactModal {
    &__Overlay {
      &--after-open {
        background-color: rgba($black, 0.4);
        opacity: 1;
      }

      &--before-close {
        background-color: rgba($black, 0.6);
        opacity: 0;
      }
    }
  }
}

// Use the arduino modal
#root .arduino-store-modal {
  // sass-lint:disable-line no-ids force-element-nesting
  @include font(opensans, regular);

  background-color: $white;
  border-radius: 5px;
  border-top: 0;
  color: $dust;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  letter-spacing: 0.7px;
  line-height: 1.4;
  margin-top: 0;
  max-width: 875px;
  opacity: 0;
  overflow-y: scroll;
  padding: 70px;
  position: relative;
  transform: translateY(-10vh);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  width: calc(100vw - 40px);

  .close-modal-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 20px;
    height: 20px;

    @media screen and (max-width: 760px) {
      top: 15px;
      right: 15px;
    }

    .close-icon {
      width: 100%;
      height: 100%;

      polygon {
        fill: $black;
      }
    }
  }

  @media screen and (max-width: 760px) {
    max-height: calc(100vh - #{$layout-spacing-lg});
    padding: 32px;
    width: calc(100vw - #{$layout-spacing-lg});

    #heading {
      font-size: 25px;
    }
  }

  #full {
    &_description {
      flex: 1;
      overflow-x: auto;
      font-size: 20px;
      margin: 48px 0;

      ul {
        li {
          padding-left: 15px;
          position: relative;

          &::before {
            background-color: $black;
            border-radius: 50%;
            content: '';
            display: block;
            height: 5px;
            left: 0;
            position: absolute;
            top: 12px;
            width: 5px;
          }
        }
      }
    }
  }

  &.ReactModal {
    &__Content {
      &--after-open {
        opacity: 1;
        transform: translateY(0%);
      }

      &--before-close {
        opacity: 0;
        transform: translateY(-10vh);
      }
    }
  }

  &:focus {
    outline: 0;
  }

  h6 {
    @include font(opensans, regular);

    margin-top: 8px;
    text-transform: uppercase;
  }

  h3 {
    @include font(opensans, regular);

    color: $black;
    font-size: 28px;
    letter-spacing: 0.75px;
    margin: 0;
    text-transform: uppercase;
  }

  p {
    letter-spacing: 0.75px;
    margin: 0 0 1em;
    white-space: pre-line;
  }

  @mixin modal-button($color1) {
    @include ardu-store-button($color1, $white);

    font-size: 18px;
    letter-spacing: 1.14px;
    line-height: 22px;
    margin-left: $layout-spacing-sm;

    &.secondary {
      @include ardu-store-button($color1, transparent);

      font-size: 16px;
      letter-spacing: 1.14px;
      line-height: 22px;
    }
  }

  &.primary {
    .modal-store-button {
      @include modal-button($teal2);
    }
  }

  &.warning {
    .modal-store-button {
      @include modal-button($carrot);
    }
  }

  &.danger {
    .modal-store-button {
      @include modal-button($teal3);
    }
  }

  .modal-cancel-button {
    @include modal-button(#c11f09);
  }

  .buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    @include breakpoint-below($breakpoint-580) {
      flex-direction: column;
      justify-content: center;

      .modal-store-button {
        margin-left: 0;
        margin-top: $layout-spacing-xs;
      }
    }
  }

  .modal-store-button {
    &,
    &.secondary {
      font-size: 16px;
    }

    &.downgrade-button {
      @include modal-button($teal3);
    }
  }
}
