@import "~arduino-sass/src/buttons";
@import "~arduino-sass/src/modal";
@import "~arduino-sass/src/typography";
@import "~arduino-sass/src/variables";
@import "../../../styles/variables";

#root .well { // sass-lint:disable-line no-ids force-element-nesting
  margin-bottom: 21px;

  .well-title {
    @include font(opensans, regular);

    font-size: 13px;
    letter-spacing: 1.39px;
    line-height: 15px;
    margin: 0 0 12px;
    text-transform: uppercase;
  }

  .well-content {
    background-color: $feather;
    border: 1px solid $fog;
    border-radius: 5px;
    font-size: 20px;
    letter-spacing: .01em;
    line-height: 170%;
    padding: 15px;
    position: relative;

    &.white {
      background-color: $white;
    }

    ul {
      margin: 0;
    }
  }
}
