@import "~arduino-sass/src/buttons";
@import "~arduino-sass/src/typography";
@import "~arduino-sass/src/variables";
@import "../../../styles/variables";
@import "../../../styles/mixins";

// Transitions
$transition-duration: 0.2s;

#root .enterprise-plan-addon-card-wrapper {
  // sass-lint:disable-line no-ids force-element-nesting
  background-color: $feather;
  border: 1px solid #ecf1f1;
  border-radius: 6px;
  margin-bottom: 16px;
  cursor: pointer;

  &:hover {
    border: 1px solid #00979d;
  }

  .addon-card {
    display: flex;
    padding: 16px;
    align-items: center;
    justify-content: space-between;

    .selected {
      color: $teal3;
    }

    &__enterprise-chip {
      padding: 2px 4px;
      text-transform: uppercase;
      border: 1px solid $smoke;
      font-size: 12px;
      font-weight: 500;
      border-radius: 60px;
      margin-left: 8px;
      background-color: $clouds;
    }

    &__img {
      &--foundries {
        width: 220px;
        height: 20px;
        object-fit: cover;
        margin-left: -50px;
      }

      &--edgeimpulse {
        margin-left: 8px;
        height: 20px;
      }
    }

    &__text-section {
      display: flex;

      @include breakpoint-below($breakpoint-650) {
        flex-direction: column;
        align-items: center;
      }
    }

    &__title {
      color: $jet;
      margin-bottom: 8px;
      font-weight: bold;
      display: flex;
      align-items: center;

      &--current {
        padding: 5px 8px;
        background: #ecf1f1;
        border-radius: 2px;
        border: 1px solid #c9d2d2;
        margin-left: 12px;
        color: black;
        font-size: 12px;
        text-transform: none;
        font-weight: normal;
      }

      @include breakpoint-below($breakpoint-580) {
        text-align: center;
      }
    }

    &__powered {
      display: flex;
      align-items: center;

      picture {
        display: grid;
      }
    }

    &__sub-title {
      font-size: 10px;
      color: #7f8c8d;
      font-weight: 400;
      margin-left: 8px;
      min-width: 55px;
    }

    &__text {
      display: flex;
      flex-direction: column;
      width: 100%;

      @include breakpoint-below($breakpoint-768) {
        width: 75%;
      }

      @include breakpoint-below($breakpoint-580) {
        width: 100%;
        margin: 8px 0;
      }
    }

    &__sub-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__price {
      margin: 0 8px;
      font-size: 20px;
      font-weight: bold;
      width: 10%;
      min-width: 85px;

      &--details {
        color: $dust;
        font-weight: 400;
        font-size: 12px;
        white-space: nowrap;
      }

      @include breakpoint-below($breakpoint-650) {
        min-width: 60px;
      }

      @include breakpoint-below($breakpoint-580) {
        width: 100%;
        margin: 8px 0;
        text-align: center;
      }
    }

    @include breakpoint-below($breakpoint-650) {
      display: flex;
      flex-direction: column;
    }
  }

  .feature-wrapper {
    background-color: #fff;
    border-radius: 6px;
    padding: 16px;
    line-height: 170%;
    display: flex;

    &__device-numbers,
    &__available-devices,
    &__popover {
      margin: 0 4px;
    }

    h6 {
      font-size: 14px;
      margin-top: 0;
      margin-bottom: 24px;
    }

    &__devices {
      flex: 0 0 65%;
      padding: 8px 24px 8px 8px;
    }

    &__change {
      flex: 0 0 35%;
      padding: 8px 8px 8px 12px;
      border-left: 1px solid #dae3e3;
      display: flex;
      align-items: center;

      @include breakpoint-below($breakpoint-768) {
        flex-wrap: wrap;
        justify-content: center;
      }
    }

    &__device-numbers {
      font-weight: 700;
      font-size: 16px;
      color: #000;
    }

    &__available-devices {
      font-weight: 400;
      font-size: 12px;
      color: $dust;

      @include breakpoint-below($breakpoint-768) {
        text-align: center;
      }

      &--text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .tertiary-button {
    font-family: "Open Sans", "Lucida Grande", Lucida, Verdana, sans-serif;
    background-color: transparent;
    border: 1px solid transparent;
    color: $teal3;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    letter-spacing: 1.28px;
    line-height: normal;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;

    @include breakpoint-below($breakpoint-1200) {
      margin-left: 8px;
    }

    &:hover {
      color: $teal5;
    }
  }
}
