@import "~arduino-sass/src/buttons";
@import "~arduino-sass/src/mixins";
@import "~arduino-sass/src/variables";
@import "../../../styles/mixins";
@import "../../../styles/variables";

#root .payment-button {
  display: flex;
  flex-direction: column;
  margin: 8px;
  align-items: center;
}

.payment-method-well {
  // sass-lint:disable-line no-ids force-element-nesting
  .credit-card-info {
    svg {
      height: 26px;
    }
  }

  .info-title {
    font-weight: bold;
    font-size: 12px;
    line-height: 170%;
    letter-spacing: 0.01em;
    color: #434f54;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  .billing-wrapper {
    border: 1px solid #3fae98;
    background-color: $white;
    padding: 8px 24px;
    border-radius: 4px;
  }
}

.info-title {
  font-weight: bold;
  font-size: 12px;
  line-height: 170%;
  letter-spacing: 0.01em;
  color: #434f54;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.subscription-activations {
  // sass-lint:disable-line no-ids force-element-nesting
  width: 100%;

  &__wrapper {
    background-color: $feather;
    border: 1px solid #dae3e3;
    border-radius: 4px;
    margin-bottom: 25px;
    padding: 25px;
    border-left-width: 5px;

    &--left {
      flex: 0 0 100%;
    }
  }

  &__title {
    font-weight: 400;
    font-size: 28px;
    line-height: 170%;
    color: $jet;
    margin-bottom: 28px;
    text-align: left;
    display: flex;
    align-items: center;
  }

  &__time {
    margin-left: 8px;
    padding: 5px 8px;
    color: $black;
    background-color: #f1c40f;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Roboto Mono", monospace;
    letter-spacing: 0.1em;
    line-height: 1;
  }

  &__sub-title {
    font-weight: 700;
    font-size: 12px;
    line-height: 170%;
    color: #434f54;
    margin: 12px 0;
    letter-spacing: 0.01em;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;

    @include breakpoint-below($breakpoint-768) {
      flex-direction: column;
    }
  }

  &__container {
    display: flex;
  }

  &__box {
    //font-family: "Open Sans";
    letter-spacing: 0.01em;
    margin: 5px 10px 5px 0;
    padding: 16px;
    background-color: #fff;
    border: 1px solid #c9d2d2;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    color: #434f54;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__banner {
    background-color: #fff;
    border: 1px solid $silver;
    border-radius: 3px;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 20px;
    padding: 12px;
  }

  &__icon {
    &--mr {
      margin-right: 8px;
    }

    &--voucher {
      svg {
        position: relative;
        top: 2px;
        height: 16px;
      }
    }
  }

  &__voucher {
    letter-spacing: 1.92px;
  }
}
