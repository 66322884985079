@import "~arduino-sass/src/mixins";
@import "~arduino-sass/src/variables";
@import "../../../../styles/mixins";
@import "../../../../styles/variables";

.redeem-modal {
  padding: 0 !important;
  width: 700px;

  &__content {
    padding: 0 !important;

    img {
      width: 100%;
    }

    h4 {
      font-size: 20px;
      margin-bottom: 0;
    }

    p {
      font-size: 14px;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        padding: 8px;
        font-size: 14px;

        svg {
          margin-right: 16px;
          margin-bottom: 2px;
        }
      }
    }

    /* stylelint-disable */
    .modal__buttons {
      padding: 24px 130px;
      background-color: $feather;
      margin: 0;
      justify-content: center;

      @include breakpoint-below($breakpoint-580) {
        padding: 16px 44px;
      }
    }
  }

  &__text-box {
    padding: 28px 130px 36px;

    @include breakpoint-below($breakpoint-580) {
      padding: 28px 44px 36px;
    }
  }

  &__duration {
    display: inline-block;
    @include font(roboto-mono, regular);
    color: $jet;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%; /* 20.4px */
    letter-spacing: 1.2px;

    margin-bottom: 16px;
  }

  &__warning {
    display: inline-block;
    padding-top: 20px;
    border-top: 1px solid $smoke;
    margin-top: 28px;

    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%; /* 20.4px */
    letter-spacing: 0.12px;

  }
}
