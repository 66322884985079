@import "~arduino-sass/src/buttons";
@import "~arduino-sass/src/radiobutton";
@import "~arduino-sass/src/variables";
@import "~arduino-sass/src/spacing";
@import "../../../styles/mixins";
@import "../../../styles/variables";

#root {
  .form-fields {
    .plan {
      display: flex;
      background: white;
      border-radius: $space-xx-small;
      padding: 24px $space-medium $space-medium;
      flex-direction: column;
      width: 100%;
    }

    .submit-button,
    .cancel-button {
      @include ardu-store-button($teal3, $white);

      margin-left: $space-large;
      margin-top: $layout-spacing-lg;

      @include breakpoint-below($breakpoint-580) {
        margin-left: 0;
      }
    }

    .disabled {
      opacity: 0.5 !important;
    }
  }
}
