@import "~arduino-sass/src/buttons";
@import "~arduino-sass/src/checkbox";
@import "~arduino-sass/src/inputfield";
@import "~arduino-sass/src/mixins";
@import "~arduino-sass/src/radiobutton";
@import "../../../styles/mixins";
@import "../../../styles/variables";

.credit-card-info {
  align-items: center;
  display: flex;
  flex-wrap: wrap;

  svg {
    height: 18px;
    margin-right: 15px;
    vertical-align: bottom;
  }

  .mobile-column-content {
    align-items: center;
    display: flex;
    flex: 1 1 auto;

    @include breakpoint-below($breakpoint-580) {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  .expired-card {
    color: $notifyerror;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 170%; /* 20.4px */
    letter-spacing: 0.12px;
    display: flex;
    align-items: center;

    svg {
      width: 15px;
      height: 15px !important;
      margin-right: 8px !important;
    }
  }

  .right-content {
    @include breakpoint-above($breakpoint-580) {
      margin-left: auto;
    }

    @include breakpoint-below($breakpoint-580) {
      flex: 1 1 100%;

      > div {
        margin-top: $layout-spacing-xs;
      }
    }
  }

  .brand {
    @include font(opensans, regular);

    margin-right: $layout-spacing-xs;
  }

  .last-4 {
    @include breakpoint-below($breakpoint-580) {
      margin-top: $layout-spacing-xxs;
    }
  }

  .expiry-container {
    @include breakpoint-above($breakpoint-580) {
      margin-left: auto;
    }
  }

  .expiry {
    @include font(roboto-mono, regular);
  }

  &.clickable {
    cursor: pointer;
  }
}
