@import "~arduino-sass/src/variables";
@import "~arduino-sass/src/checkbox";
@import "../../../styles/variables";

#root .seats-quantity-picker {
  display: flex;
  justify-content: center;

  .quantity-modifier-minus-transparent {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 40px;
    z-index: 10;
  }

  .quantity-modifier-minus,
  .quantity-modifier-plus {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin: 0;
    border-radius: 30px;
    background-color: rgb(0 92 95 / 10%);

    &.disabled {
      cursor: not-allowed;

      svg {
        fill: $dust;
      }
    }

    &:hover:enabled {
      background-color: $teal2;
    }
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  input[type="number"] {
    appearance: textfield;
  }

  .placeholder {
    position: relative;
    flex: 50%;
    width: 100%;
    margin: 0 16px;
  }

  .placeholder::after {
    position: absolute;
    left: 55%;
    top: 20%;
    content: attr(data-placeholder);
    pointer-events: none;
    color: $asbestos;
  }

  .quantity-input {
    background: transparent;
    border: 1px solid $smoke;
    border-radius: 3px;
    color: $black;
    font-size: $font-size-md;
    text-align: center;
    outline: none;
    width: 100%;
    height: 100%;

    &:hover {
      border: 1px solid #000;
    }

    &:focus {
      border: 1px solid $teal0;
    }
  }
}

.helper-text {
  font-size: 12px;
  color: $dust;
  margin-left: 55px;
}
