@import "~arduino-sass/src/buttons";
@import "~arduino-sass/src/typography";
@import "~arduino-sass/src/variables";
@import "../../../styles/variables";
@import "../../../styles/mixins";

// Transitions
$transition-duration: 0.2s;

#root .cloud-plan-product-card-wrapper {
  // sass-lint:disable-line no-ids force-element-nesting
  background-color: $feather;
  border: 1px solid #ecf1f1;
  border-radius: 6px;
  margin-bottom: 16px;
  cursor: pointer;

  &:hover {
    border: 1px solid #00979d;
  }

  .selected {
    border: 1px solid #00979d;
  }

  .product-card {
    display: flex;
    padding: 16px;
    align-items: center;
    justify-content: space-between;

    &__title {
      color: $jet;
      margin-bottom: 8px;
      text-transform: uppercase;
      font-weight: bold;

      &--current {
        padding: 5px 8px;
        background: #ecf1f1;
        border-radius: 2px;
        border: 1px solid #c9d2d2;
        margin-left: 12px;
        color: black;
        font-size: 12px;
        text-transform: none;
        font-weight: normal;
      }

      @include breakpoint-below($breakpoint-580) {
        text-align: center;
      }
    }

    &__text {
      display: flex;
      flex-direction: column;
      width: 60%;
      margin-right: 8px;

      @include breakpoint-below($breakpoint-768) {
        width: 50%;
      }

      @include breakpoint-below($breakpoint-580) {
        width: 100%;
        margin: 8px 0;
      }
    }

    &__sub-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__price {
      margin: 0 8px;
      font-size: 20px;
      font-weight: bold;
      width: 10%;
      min-width: 85px;

      @include breakpoint-below(650px) {
        min-width: 60px;
      }

      @include breakpoint-below($breakpoint-580) {
        width: 100%;
        margin: 8px 0;
        text-align: center;
      }
    }

    &__more {
      margin: 0 8px;
      width: 10%;
      min-width: 80px;
      z-index: 10;

      @include breakpoint-below($breakpoint-580) {
        width: 100%;
        margin: 8px 0;
        text-align: center;
      }
    }

    @include breakpoint-below($breakpoint-580) {
      display: flex;
      flex-direction: column;
    }
  }

  .feature-wrapper {
    background-color: #fff;
    border-radius: 6px;
    padding: 16px;
    margin: 16px;

    .feature {
      &__list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        @include breakpoint-below($breakpoint-580) {
          display: block;
        }
      }

      &__item {
        flex: 0 0 50%;
        margin-bottom: 16px;

        &--box {
          display: flex;
          align-items: center;

          @include breakpoint-below($breakpoint-580) {
            svg {
              min-width: 23px;
              min-height: 23px;
            }
          }
        }

        &--space {
          margin-left: 8px;
        }
      }
    }
  }

  .more-button {
    font-family: "Open Sans", "Lucida Grande", Lucida, Verdana, sans-serif;
    background-color: transparent;
    border: 1px solid transparent;
    color: $teal3;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    letter-spacing: 1.28px;
    line-height: normal;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;

    &:hover {
      color: $teal5;
    }
  }
}
