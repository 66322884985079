@import "~arduino-sass/src/variables";
@import "../../../styles/mixins";
@import "../../../styles/variables";

#root .page-home { // sass-lint:disable-line no-ids force-element-nesting
  min-height: calc(100vh - #{$app-bar-height});
  padding: $layout-spacing-lg 0;

  .products-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    max-width: $page-max-width;
    padding: 0 $layout-spacing;
    text-align: left;

    @include breakpoint-between($breakpoint-580, $breakpoint-980) {
      padding: 0 $layout-spacing;
    }

    @include breakpoint-below($breakpoint-580) {
      padding: 0 $layout-spacing-sm;
    }
  }
}
