@import "~arduino-sass/src/buttons";
@import "~arduino-sass/src/variables";
@import "~arduino-sass/src/notifications";
@import "../../../../styles/variables";
@import "../../../../styles/mixins";

#root {
  .payment-spinner-wrapper {
    height: 20vh;
    position: relative;
  }

  .purchase-payment {
    position: relative;

    .pay-button-container {
      display: flex;
      justify-content: flex-end;
      margin-top: $layout-spacing-lg;
      width: 100%;

      .pay-button {
        @include ardu-store-button($teal3, $white);

        &:disabled {
          opacity: 0.5;
        }
      }
    }

    .card-group {
      margin-right: 28px;

      .card-icon {
        margin-left: 0;
        margin-right: 5px;
        transition: opacity $transition-timing $transition-easing;
        width: 40px;
      }

      @include breakpoint-below(440px) {
        flex-direction: row;
        margin-bottom: -15px;
        margin-left: 40px;
        margin-right: 0;
        padding-right: 0;
      }
    }

    .back-button-payment {
      @include ardu-store-button($teal3, $white);
      @include size(42px);

      font-size: $layout-spacing-sm;
      margin-right: ($layout-spacing-xs * 2);
      padding: 8px;

      svg {
        fill: $carrot;
        left: 1px;
        position: absolute;
        top: 2px;
      }
    }

    .form-fields {
      margin: 16px 0;
    }

    .arduino-form {
      position: relative;

      .form-fields {
        margin-top: 0 !important;

        &.hidden {
          opacity: 0;
        }

        .form-item {
          flex: 0 0 50%;
        }

        .form-group {
          align-items: baseline;

          @include breakpoint-below($breakpoint-580) {
            align-items: center;
          }
        }
      }
    }

    .payment-method-title {
      color: #434f54;
      font-weight: 700;
      font-size: 12px;
      line-height: 170%;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      text-align: left;
    }

    &.arduino-form.indent {
      .form-fields {
        padding: 0 $layout-spacing-sm;

        .form-item {
          padding-left: 26px;
        }
      }
    }

    .ardu-radio {
      cursor: pointer;
      margin: 0 !important;

      .custom-radio {
        margin: 0;
      }

      h4 {
        @include font(opensans, regular);

        line-height: 24px;
        margin: 0;
        padding-left: 17px;
      }
    }

    .absolute-spinner-container {
      background-color: $feather;
      bottom: 0;
      height: 100%;
      z-index: 1;
    }

    .error-notification {
      @extend %error-notification;

      margin: $layout-spacing-sm $layout-spacing-xs 0;
      width: 100%;
    }

    .terms-wrapper {
      display: flex;
      justify-content: flex-end;
      margin-top: 24px;
      min-height: 46px;
      width: 100%;

      .checkbox-wrapper {
        display: flex;
        flex-direction: column;

        .terms-link {
          text-transform: unset;
        }

        .error-message {
          @include font(opensans, regular);

          color: $notifyerror;
          font-size: 16px;
          letter-spacing: 0.7px;
          padding-top: 5px;
        }
      }
    }

    .credit-card-list {
      width: 100%;
    }

    .use-new-card-section {
      width: 100%;
      margin-top: 32px;

      .header-panel {
        align-items: center;
        display: flex;
        font-size: 20px;
        height: 70px;
      }

      &.expanded {
        .use-new-card-form {
          border: 1px solid $teal2;
          border-radius: 7px;
          max-height: 400px;
          padding-bottom: 30px;
          transition: max-height 0.25s ease-in;

          .ardu-radio {
            display: flex;
            padding-left: 12px;
          }

          @include breakpoint-below($breakpoint-580) {
            max-height: 580px;
          }
        }

        .use-new-card {
          display: none;
        }
      }

      .use-new-card {
        align-items: center;
        background-color: $white;
        color: #000 !important;
        cursor: pointer;
        display: flex;
        height: 50px;
        position: relative;
        width: 100%;

        &::after {
          color: #000;
          content: "\002B";
          font-size: 38px;
          left: 13px;
          position: absolute;
          top: 0;
        }

        h4 {
          @include font(opensans, regular);

          color: #000;
          line-height: 24px;
          margin: 0;
          padding-left: $layout-spacing-lg;
        }
      }

      .use-new-card-form {
        background-color: $white;
        border: 1px solid $fog;
        border-radius: 7px;
        max-height: 70px;
        overflow: hidden;
        width: 100%;

        .ardu-radio {
          display: none;
        }

        .submit-button-container {
          display: none;
        }

        .form-group {
          margin: 0 auto;
          width: calc(100% - 80px);
          text-align: left;

          .form-item {
            padding-left: 0;
          }
        }

        &.no-cards {
          background-color: transparent;
          border: 0;
          padding-bottom: 0;

          .header-panel {
            display: none;
          }

          .form-group {
            width: 100%;
          }

          .card-group {
            margin-right: -12px;

            @include breakpoint-below(440px) {
              margin-left: 0;
            }
          }
        }
      }
    }
  }

  .discount-code {
    font-weight: bold;
    font-size: 14px;
    color: $teal3;
    text-decoration: underline;
    text-align: left;
    width: 100%;

    &:hover {
      cursor: pointer;
    }
  }

  .discount-message {
    font-weight: bold;
    font-size: 12px;
    line-height: 170%;
    color: #1da086;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;

    .discount-text {
      margin-left: 4px;
      font-weight: 700;
    }
  }

  .discount-error {
    font-weight: bold;
    font-size: 12px;
    line-height: 170%;
    color: #da5b4a;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;

    .discount-text {
      margin-left: 4px;
      font-weight: 700;
    }

    @include breakpoint-below(440px) {
      margin-left: 0;
    }
  }

  .discount-title {
    display: flex;
    height: 35px;
    justify-content: space-between;
    letter-spacing: 1.3px;
    padding: 8px;
    background-color: #e8f5f3;
    border-radius: 4px;
    font-weight: bold;

    .value {
      color: #000;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .apply-button-container {
    padding: 16px;
    text-align: start;

    .apply-button {
      @extend %text-link;

      text-transform: uppercase;
      font-weight: bold;
    }
  }

  .hr-style {
    margin: 24px 0 !important;
  }

  .mt-12 {
    margin-top: 12px !important;
    text-align: right;
  }

  .coupon-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 24px;

    &__form {
      flex: 0 0 50%;

      &--opened {
        flex: 0 0 100%;
      }
    }

    &__box {
      flex: 0 0 calc(50% - 16px);
      display: flex;
      background: #dae3e3;
      border-radius: 6px;
      padding: 20px;
      justify-content: space-between;
      margin-left: 16px;
      align-items: center;
      cursor: pointer;
      min-height: 85px;

      &--hided {
        display: none;
      }
    }

    &__icon {
      flex: 0 0 10%;
      display: flex;
      justify-content: center;
    }

    &__text {
      flex: 0 0 54%;
      font-weight: 700;
      font-size: 16px;
      margin-left: 10px;

      &--small {
        flex: 0 0 45%;
        font-weight: 700;
      }
    }

    &__cta {
      flex: 0 0 36%;

      &--light {
        font-weight: 700;
        color: $teal3;
      }

      &--large {
        flex: 0 0 45%;
      }
    }

    @include breakpoint-below(600px) {
      flex-direction: column;
      margin-bottom: 8px;

      &__box {
        margin-left: 0;

        &:first-child {
          margin-bottom: 8px;
        }
      }
    }
  }

  .main-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 170%;
  }

  .sub-text {
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;

    &__icon {
      margin-right: 12px;
    }
  }

  .delete-button {
    font-size: 14px;
    text-transform: uppercase;
    color: $teal3;
    font-weight: bold;

    &:hover {
      cursor: pointer;
      color: $teal1;
    }
  }

  .link {
    color: $teal3;
    font-weight: bold !important;
  }
}
