/* stylelint-disable */
@import '~arduino-sass/src/buttons';
@import '~arduino-sass/src/radiobutton';
@import '~arduino-sass/src/variables';
@import '~arduino-sass/src/spacing';
@import '../../../styles/mixins';
@import '../../../styles/variables';

#root {
  .slider-container {
    padding: 0 8px;
  }
  //Slider root
  .MuiSlider-colorPrimary.MuiSlider-sizeMedium.MuiSlider-root.MuiSlider-marked {
    margin-top: 30px;
    margin-bottom: 0px;
  }
  //Rail selected
  .MuiSlider-track {
    color: $teal3;
    border: 2px solid $teal3;
  }

  //Rail unselected
  .MuiSlider-rail {
    color: $asbestos;
    opacity: 1;
  }

  // Thumb selector
  .MuiSlider-thumbColorPrimary.MuiSlider-thumbSizeMedium.MuiSlider-thumb {
    width: 22px;
    height: 22px;
    background-color: white;
    padding: 8px;

    &:hover {
      box-shadow: 0px 0px 0px 8px rgb(0 129 132 / 16%);
    }
  }

  //Thumb Hovering selected / active
  .MuiSlider-thumbColorPrimary.MuiSlider-thumbSizeMedium.MuiSlider-thumb.Mui-focusVisible {
    &.Mui-focusVisible {
      box-shadow: 0px 0px 0px 8px rgb(0 129 132 / 16%);
    }

    &.Mui-active {
      box-shadow: 0px 0px 0px 16px rgb(0 129 132 / 16%);
    }
  }

  //Mark label
  .MuiSlider-markLabel {
    top: -35px;
  }

  .MuiSlider-mark {
    top: -7px;
    background-color: $smoke;
    height: 10px;
    width: 3px;
    border-radius: 10px;
  }
}
