/* stylelint-disable */
@import '~arduino-sass/src/buttons';
@import '~arduino-sass/src/mixins';
@import '~arduino-sass/src/variables';
@import '../../../styles/mixins';
@import '../../../styles/variables';

#root .subscriptions-page {
  // sass-lint:disable-line no-ids force-element-nesting
  background-color: $clouds;
  display: flex;
  justify-content: center;
  margin-top: 0;
  min-height: calc(100vh - #{$app-bar-height});
  padding: $layout-spacing-lg $layout-spacing;

  @include breakpoint-below($breakpoint-580) {
    padding: ($layout-spacing-sm) $layout-spacing-xs;
  }

  .tabs {
    max-width: 1400px;
    overflow: hidden;
    width: 100%;
  }

  .tabs-header {
    position: fixed;
    top: 0;
    z-index: 50;
    align-items: center;
    right: calc(50% - 60px);

    a {
      display: inline-block;
      padding: 16px;
      color: $asbestos;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 170%; /* 23.8px */
      letter-spacing: 0.14px;
      transition: all 0.3s;

      &:after {
        content: '';
        display: block;
        width: calc(100% + 32px);
        height: 4px;
        background-color: transparent;
        position: relative;
        top: 19px;
        left: -16px;
        transition: all 0.3s;
      }

      &.active {
        color: $teal3;

        &:after {
          background-color: $teal3;
        }
      }
    }
  }


  .tabs-content {
    background-color: $white;
    border: 1px solid $smoke;
    border-radius: 6px;
    display: flex;
    max-width: 100%;
    padding: 44px;
    position: relative;
    text-align: left;

    @include breakpoint-below($breakpoint-580) {
      padding: $layout-spacing-xs;
    }

    > div {
      position: relative;
      width: 100%;
    }
  }
}
