@import "~arduino-sass/src/variables";
@import "~arduino-sass/src/checkbox";
@import "../../../styles/variables";

#root .digital-store-checkbox {
  @extend %ardu-checkbox;

  pointer-events: none;

  .custom-checkbox {
    pointer-events: initial;

    &:disabled {
      background-color: red !important;
    }
  }

  a {
    pointer-events: initial;
  }

  &.left {
    flex-direction: row-reverse;

    .custom-checkbox {
      margin-left: 10px;
      margin-right: 0;
    }
  }

  &.error {
    .custom-checkbox {
      border: 1px solid $notifyerror;
    }
  }
}
