@import "~arduino-sass/src/buttons";
@import "~arduino-sass/src/modal";
@import "~arduino-sass/src/typography";
@import "~arduino-sass/src/variables";
@import "../../../styles/variables";
@import "../../../styles/mixins";

// override library style T
.toastify {
  &__toast-container {
    &--bottom-center {
      bottom: 20px !important;
      left: 50%;
      min-height: 35px;
      padding: 0;
    }
  }
}

.new-toast {
  // sass-lint:disable-line no-ids force-element-nesting
  align-items: center;
  color: $white;
  cursor: default;
  display: flex;
  min-height: 35px;
  justify-content: center;
  padding: 0 16px;
  width: 100%;

  a {
    color: $teal0;
  }

  &.success {
    background: #374146;
  }

  &.warning {
    background: #374146;
  }

  &.error {
    background: #374146;
  }

  &.shown {
    opacity: 1;
    transform: translate(-50%, 0);
    transition: transform 0.4s ease-in-out, opacity 0.2s ease-in-out 0.2s;
  }

  .toast-body {
    @include font(opensans, regular);

    font-size: 14px;
  }

  .close-icon {
    cursor: pointer;
    position: absolute;
    right: 12px;
  }
}

.snackbar {
  position: relative;
  left: calc(50% - 355px);
  display: flex;
  min-width: 710px;
  min-height: 52px;
  padding: 8px 24px;
  align-items: center;
  gap: 16px;
  border-radius: 16px;

  @include breakpoint-below($breakpoint-580) {
    min-width: 100%;
    left: 0;
  }

  &.success {
    background-color: $notifysuccess;
    color: $feather;

    .close-icon {
      svg {
        fill: $feather;
      }
    }
  }

  &.error {
    background-color: $notifyerror;
    color: $feather;

    .close-icon {
      svg {
        fill: $feather;
      }
    }
  }

  &.warning {
    background-color: $notifywarning;
    color: $jet;

    .close-icon {
      svg {
        fill: $jet;
      }
    }

    .snackbar-body {
      border-color: rgba($jet, 0.5);
    }
  }

  .snackbar-body {
    @include font(opensans, regular);

    font-size: 14px;
    font-weight: 600;
    border-right: solid 1px rgba($feather, 0.5);
  }

  .close-icon {
    cursor: pointer;
    position: relative;
    top: 2px;
  }
}
