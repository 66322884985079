@import "~arduino-sass/src/buttons";
@import "~arduino-sass/src/radiobutton";
@import "~arduino-sass/src/variables";
@import "~arduino-sass/src/spacing";
@import "../../../styles/mixins";
@import "../../../styles/variables";

#root {
  .form-fields {
    margin-top: 24px;

    .plan {
      display: flex;
      background: white;
      border-radius: $space-xx-small;
      padding: 24px $space-medium $space-medium;
      flex-direction: column;
      width: 100%;

      .plan-title {
        font-size: $space-medium;
        color: $dust;
        line-height: 170%;
        letter-spacing: 0.01em;
        margin-bottom: $space-medium;
      }

      .plan-seats {
        display: flex;
        margin-bottom: 32px;

        @include breakpoint-below($breakpoint-580) {
          flex-direction: column;
        }

        .box {
          background-color: $feather;
          border-radius: $space-xx-small;
          padding: 24px;
          width: 100%;
          text-align: center;

          &:nth-child(2) {
            margin: 0 24px;

            @include breakpoint-below($breakpoint-580) {
              margin: 0 0 12px !important;
            }
          }

          .title {
            font-size: $space-medium;
            text-align: center;
          }

          .price {
            font-size: 14px;
            color: $asbestos;

            .dollars {
              font-weight: bold;
              font-size: $space-large;
              color: black;
            }
          }

          @include breakpoint-below($breakpoint-580) {
            margin-bottom: 12px;
          }
        }

        .active {
          background-color: #e8f5f3;
        }
      }

      .plan-institution {
        font-size: 16px;
        line-height: 170%;
        letter-spacing: 0.01em;
        color: $dust;
        display: flex;
        align-items: center;
        margin: 0 12px 42px;

        .plan-institution-text {
          margin-left: 12px;
        }
      }
    }

    .submit-button,
    .cancel-button {
      @include ardu-store-button($teal3, $white);

      margin-left: $space-large;
      margin-top: $layout-spacing-lg;

      @include breakpoint-below($breakpoint-580) {
        margin-left: 0;
      }
    }

    .disabled {
      opacity: 0.5 !important;
    }
  }
}
