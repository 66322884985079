@import "~arduino-sass/src/mixins";
@import "~arduino-sass/src/buttons";
@import "~arduino-sass/src/variables";
@import "../../../../styles/mixins";
@import "../../../../styles/variables";

.vouchers-list {
  position: relative;

  &__empty-state {
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    background-color: $feather;
    color: $dust;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%; /* 27.2px */
    letter-spacing: 0.16px;
  }

  &__table {
    width: 100%;
    border-spacing: unset;
  }

  &__table-header {
    background-color: $feather;

    th {
      padding: 8px;
      border-spacing: unset;
      color: $dust;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 170%; /* 27.2px */
      letter-spacing: 0.16px;
    }
  }

  &__table-row {
    height: 75px;

    td {
      border-bottom: 1px solid $fog;
      padding: 8px;
      color: $dust;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 170%; /* 27.2px */
      letter-spacing: 0.16px;
    }
  }

  &__features {
    display: flex;
    align-items: center;

    svg {
      margin-left: 8px;
    }

    &-tooltip {
      div {
        font-family: "Open Sans", "Lucida Grande", Lucida, Verdana, sans-serif;
        background-color: $white;
        color: $jet;
        box-shadow: 0 4px 20px 0 rgba($black, 0.25);
        padding: 16px 24px;
        line-height: 170%; /* 27.2px */

        b {
          display: inline-block;
          margin-bottom: 4px;
        }

        a {
          color: $teal3;
          font-weight: 700;
        }
      }
    }
  }

  &__status {
    display: flex;
    align-items: center;
  }

  &__dot {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 4px;

    &--active {
      border: 1px solid $notifysuccess;
      background-color: $notifysuccesslight;
    }

    &--expired {
      border: 1px solid $notifyerror;
      background-color: $notifyerrorlight;
    }
  }
}
