@import "~arduino-sass/src/buttons";
@import "~arduino-sass/src/mixins";
@import "~arduino-sass/src/variables";
@import "../../../styles/mixins";
@import "../../../styles/variables";

#root .thank-you-page { // sass-lint:disable-line no-ids force-element-nesting
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - #{$layout-spacing-lg});

  .thank-you-page-container {
    display: flex;
    flex: 1 0;
    flex-direction: column;
    width: 100vw;

    .thank-you-layout-100 {
      background-color: $white;
      display: flex;
      flex: 1 0;
      justify-content: center;

      .thank-you-layout-box {
        height: auto;
        position: relative;

        .thank-you-wrapper {
          display: flex;
          justify-content: space-between;
          max-width: $page-max-width;
          text-align: left;
        }
      }
    }
  }

  &.center {
    justify-content: center;
  }

  .thank-you-box-container {
    padding: 0 20%;

    @include breakpoint-below($breakpoint-980) {
      padding: 0;
    }
  }

  .banner-box-container {
    background-color: $white;
    display: flex;
    justify-content: center;
    padding: 0 $layout-spacing;

    @include breakpoint-below($breakpoint-980) {
      padding: 0 10%;
    }
  }

  .thank-you-box {
    align-items: center;
    background-color: $clouds;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: $layout-spacing-lg 20%;
    width: 100%;

    @include breakpoint-below($breakpoint-980) {
      padding: 10%;
    }

    svg {
      max-width: 364px;
      width: 80%;
    }

    h1 {
      margin-bottom: 0;
    }

    h3 {
      @include font(opensans, regular);

      font-size: 20px;
      margin-bottom: $layout-spacing-xs;

      .email-address {
        @include font(opensans, regular);
      }
    }

    .title {
      @include font(opensans, bold);

      color: $black;
    }

    hr {
      border-bottom: 1px solid $silver;
      border-top: 0;
      margin: $layout-spacing-sm;
      width: 100%;
    }

    .box-button {
      @include ardu-store-button($teal3, $white);

      font-size: $font-size-base;
      margin-top: $layout-spacing;
    }
  }

  .banner-box {
    align-items: center;
    margin-bottom: $layout-spacing-lg;
    width: 100%;

    .banner-title {
      margin-bottom: $layout-spacing-lg;
      margin-top: $layout-spacing-lg;
      text-align: center;
    }

    .banners-container {
      @include breakpoint-above($breakpoint-980) {
        break-inside: avoid-column;
        column-count: 2;
        column-gap: 10%;
        column-rule: 1px solid rgba($gold, .5);
        padding: 0 $layout-spacing;
      }

      @include breakpoint-above($breakpoint-1200) {
        column-gap: 20%;
      }

      .banner {
        align-items: flex-start;
        break-inside: avoid;
        display: flex;
        flex: 0 0 50%;
        flex-direction: column;
        margin-bottom: $layout-spacing-lg;
        page-break-inside: avoid;
        text-align: left;

        @include breakpoint-below($breakpoint-980) {
          margin-bottom: $layout-spacing;
        }

        h2 {
          @include font(opensans, bold);

          color: $black;
          font-size: 36px;
          line-height: 40px;
          margin-top: 0;
          text-transform: capitalize;
        }

        .banner-description {
          color: $black;
          font-size: 20px;
          letter-spacing: .01em;
          line-height: 170%;
          min-height: ($layout-spacing-lg * 2);
        }

        .banner-link {
          @extend %text-link;

          font-size: $font-size-md;
          font-weight: 600;
          letter-spacing: 1.3px;
          margin-top: $layout-spacing-sm;
          text-transform: uppercase;
        }
      }

      &.create-banners-container {
        @include breakpoint-above($breakpoint-980) {
          column-count: 3;
        }

        @include breakpoint-above($breakpoint-1200) {
          column-gap: 10%;
        }
      }
    }
  }
}
