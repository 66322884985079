@import "~arduino-sass/src/buttons";
@import "~arduino-sass/src/typography";
@import "~arduino-sass/src/variables";
@import "../../../styles/variables";
@import "../../../styles/mixins";

.info-popover {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;

  svg {
    width: 16px;
    height: 16px;
  }
}

.popover-content {
  max-width: 175px;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 0.01em;
}
