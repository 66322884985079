@import "~arduino-sass/src/buttons";
@import "~arduino-sass/src/mixins";
@import "~arduino-sass/src/variables";
@import "../../../../styles/mixins";
@import "../../../../styles/variables";

.vouchers-tab {
  h4 {
    color: $jet !important;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%; /* 47.6px */
    letter-spacing: 0.28px;
    margin: 0 !important;
  }

  &__cloud-popover {
    position: fixed;
    width: 352px;
    z-index: 10;
    top: 80px;
    right: 32px;
    display: none;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 5px;
    background: $jet;
    color: $white;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%; /* 23.8px */
    letter-spacing: 0.14px;

    @include breakpoint-below(1024px) {
      right: 90px;
    }

    @include breakpoint-below($breakpoint-768) {
      right: 45px;
      width: 200px;
    }

    &.visible {
      display: flex;
    }

    &::before {
      content: "";
      display: block;
      background-color: $jet;
      width: 20px;
      height: 20px;
      position: absolute;
      top: -10px;
      right: 32px;
      transform: rotate(45deg);
    }

    b {
      display: inline-block;
      margin-bottom: 8px;
      font-size: 16px;
    }
  }
}
