@import "~arduino-sass/src/variables";
@import "~arduino-sass/src/switch";
@import "../../styles/mixins";
@import "../../styles/variables";

#root {
  .subtitle {
    width: 620px;
    padding: 16px;
    font-size: 20px;
    line-height: 170%;
    letter-spacing: 0.01em;
    text-align: center;
  }
}
