@import "~arduino-sass/src/mixins";
@import "~arduino-sass/src/variables";
@import "../../../styles/variables";

#root {
  .absolute-spinner-container {
    align-items: center;
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  .absolute-spinner {
    $spinner-color: $teal2;
    $spinner-size: 12em;

    @include spinner($spinner-color, $feather, $spinner-size);

    &.white {
      @include spinner($spinner-color, $white, $spinner-size);
    }

    &.clouds {
      @include spinner($spinner-color, $clouds, $spinner-size);
    }
  }
}
