@import "~arduino-sass/src/buttons";
@import "~arduino-sass/src/typography";
@import "~arduino-sass/src/variables";
@import "../../../styles/variables";
@import "../../../styles/mixins";

// Transitions
$transition-duration: .2s;

#root .product-card-wrapper {
  // sass-lint:disable-line no-ids force-element-nesting
  flex: 0 1 33.333%;
  overflow: hidden;
  padding: $layout-spacing-xs;
  position: relative;

  @include breakpoint-below($breakpoint-1200) {
    flex: 0 1 50%;
    max-width: 380px;
  }

  @include breakpoint-below($breakpoint-980) {
    flex: 0 1 100%;
  }

  @include breakpoint-below($breakpoint-580) {
    padding: $layout-spacing-xs 0;
  }

  .product-card {
    background-color: $white;
    border: 1px solid $smoke;
    border-radius: 6px;
    cursor: pointer;
    height: 100%;
    padding: $layout-spacing-xs;
    position: relative;
    transition-duration: $transition-duration;

    &:hover {
      background-color: $teal0;

      .product-card-title,
      .product-card-description,
      .product-card-category {
        color: $white;
      }

      .product-card-image {
        opacity: .18;

        &::after {
          background: linear-gradient(to top, $teal0 10%, rgba($white, 0) 80%);
          opacity: 1;
        }
      }

      .product-card-category {
        border-color: rgba($white, .5);
      }
    }

    .product-card-image {
      background-size: cover;
      border-radius: 4px;
      display: block;
      height: 0;
      min-height: 180px;
      opacity: 1;
      padding-bottom: 75%;
      position: relative;
      transition-duration: $transition-duration;
      transition-property: opacity;
      width: 100%;

      &::after {
        @include position(absolute, 0 0 0 0);

        content: "";
        opacity: 0;
        transition-duration: $transition-duration;
        transition-property: opacity;
      }

      &.create {
        background-image: url("../../../assets/product_create.jpg");

        @media (min-resolution: 2dppx),
        (min-resolution: 192dpi) {
          /* Retina-specific stuff here */
          background-image: url("../../../assets/product_create_2x.jpg");
        }
      }
    }

    .product-card-title,
    .product-card-description {
      transition-duration: $transition-duration;
      transition-property: color;
    }

    .product-card-title {
      @include font(roboto-mono, bold);

      font-size: 34px;
      hyphens: auto;
      letter-spacing: 3.58px;
      line-height: 36px;
      margin: 0;
      text-transform: uppercase;
      word-break: break-word;
    }

    .product-card-description {
      color: $black;
      font-size: 20px;
      letter-spacing: .01em;
      line-height: 170%;
    }

    .product-card-category {
      @include font(roboto-mono, bold);

      border-bottom: 1px solid rgba($gold, .5);
      color: $gold;
      font-size: 16px;
      letter-spacing: 2.5px;
      line-height: 30px;
      text-transform: uppercase;
    }

    .product-card-container {
      padding: 20px 35px;
    }
  }
}