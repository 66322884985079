/* stylelint-disable */
@import '~arduino-sass/src/buttons';
@import '~arduino-sass/src/mixins';
@import '~arduino-sass/src/variables';
@import '~arduino-sass/src/checkbox';
@import '~arduino-sass/src/inputfield';
@import '~arduino-sass/src/radiobutton';
@import '../../../../styles/mixins';
@import '../../../../styles/variables';

#root .activations {
  // sass-lint:disable-line no-ids force-element-nesting
  width: 100%;

  &__wrapper {
    background-color: $feather;
    border: 1px solid #dae3e3;
    border-radius: 4px;
    margin-bottom: 25px;
    padding: 25px;
    border-left-width: 5px;

    &--left {
      flex: 0 0 70%;
      border-right: 1px solid #c9d1d2;

      @include breakpoint-below($breakpoint-768) {
        border-right: none;
        border-bottom: 1px solid #c9d1d2;
        padding-bottom: 24px;
        margin-bottom: 24px;
      }
    }

    &--right {
      flex: 0 0 30%;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-left: 8px;
    }
  }

  &__title {
    font-weight: 400;
    font-size: 28px;
    line-height: 170%;
    color: $jet;
    margin-bottom: 28px;
    text-align: left;
    display: flex;
    align-items: center;
  }

  &__time {
    margin-left: 8px;
    padding: 5px 8px;
    color: $black;
    background-color: #f1c40f;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: Roboto Mono, monospace;
    letter-spacing: 0.1em;
    line-height: 1;
  }

  &__sub-title {
    font-weight: 700;
    font-size: 12px;
    line-height: 170%;
    color: #434f54;
    margin-bottom: 12px;
    letter-spacing: 0.01em;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;

    @include breakpoint-below($breakpoint-768) {
      flex-direction: column;
    }
  }

  &__container {
    display: flex;
  }

  &__box {
    font-family: 'Open Sans';
    letter-spacing: 0.01em;
    margin: 5px 10px 5px 0px;
    padding: 16px;
    background-color: #fff;
    border: 1px solid #c9d2d2;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    color: #434f54;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__banner {
    background-color: #fff;
    border: 1px solid $silver;
    border-radius: 3px;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 20px;
    padding: 12px;
  }

  &__icon {
    &--mr {
      margin-right: 8px;
    }
  }

  &__cta {
    @include ardu-store-button($teal3, $white);

    font-size: 14px;
    margin-top: auto;
    font-weight: 700;

    @include breakpoint-below($breakpoint-580) {
      margin-left: 0;
    }
  }
}

.modal {
  padding: 0 40px;

  &__buttons {
    margin: 28px 0;
    display: flex;
    justify-content: flex-end;
  }

  &__link {
    color: $teal3;
    font-weight: 700;
  }

  &__description {
    margin-bottom: 28px;
  }

  p {
    font-size: 12px;
  }

  &__loader {
    display: flex;
    justify-content: center;
  }
}

.organization {
  &--disabled {
    color: #95a5a6 !important;
    background-color: $feather !important;
    border-color: 1px solid $silver !important;
    cursor: unset;
  }

  &--not-creable {
    color: #00818480 !important;
    background-color: $feather !important;
    border-color: 1px solid $silver !important;
    cursor: unset;
  }

  &__not-compatible {
    font-family: 'Roboto Mono';
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.1em;
    color: $gris;
    padding: 4px;
    border: 1px solid $gris;
    margin-left: 16px;
  }

  &__popover {
    width: 150px;
    font-weight: 400;
    font-size: 11px;
  }

  &__wrapper {
    border: 1px solid $silver;
    border-radius: 4px;
    background-color: white;
    padding: 16px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    &--right {
      margin-left: 8px;
    }

    &--reverse {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
  }

  &__create {
    color: $teal3;
    font-weight: 700;
  }

  &__title {
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 400;
  }

  &__members {
    font-size: 12px;
    color: $jet;
  }
}

.cta-button {
  @include ardu-store-button($teal3, $white);

  font-size: 14px;
  margin-top: auto;
  font-weight: 700;

  @include breakpoint-below($breakpoint-580) {
    margin-left: 0;
  }
}

.ardu-radio {
  @extend %ardu-radio;
  cursor: pointer;
}

.custom-margin {
  margin-top: 16px;
  width: 100%;
}

.select-custom-margin {
  width: 100%;
}

.info-popover {
  z-index: 1;
  margin-left: 16px;

  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  width: 16px !important;
  height: 16px !important;

  svg {
    width: 16px !important;
    height: 16px !important;
  }
}
