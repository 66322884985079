@import "~arduino-sass/src/variables";
@import "~arduino-sass/src/typography";

@mixin breakpoint-above($breakpoint) {
  @media screen and (min-width: $breakpoint) {
    @content;
  }
}

@mixin breakpoint-below($breakpoint) {
  @media screen and (max-width: $breakpoint) {
    @content;
  }
}

@mixin breakpoint-between($breakpoint-min, $breakpoint-max) {
  @media screen and (min-width: $breakpoint-min) and (max-width: $breakpoint-max) {
    @content;
  }
}

@mixin pending-banner() {
  background-color: #fdebd0;
  color: $black;
  min-height: 140px;
  padding: 20px;

  .banner-container-title {
    align-items: center;
    display: flex;

    h6 {
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 1.39px;
      line-height: 22px;
      margin: 0;
      padding-left: 10px;
      text-transform: uppercase;
    }
  }

  .banner-description {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1.39px;
    line-height: 22px;
  }

  .cancel-button {
    cursor: pointer;
    font-size: 16px;
  }
}

@mixin past-due-banner() {
  @include font(opensans, regular);

  background-color: #f9e9e6;
  border: 1px solid $notifyerror;
  border-radius: 8px;
  color: #000;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  letter-spacing: 1.39px;
  line-height: 18px;
  padding: 16px;

  div:first-child {
    @include font(opensans, bold);

    margin-bottom: 16px;
  }
}

@mixin enterprise-banner() {
  @include font(opensans, regular);

  background-color: #fff;
  border: 1px solid $silver;
  border-radius: 3px;
  color: #000;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  letter-spacing: 0.01em;
  line-height: 24px;
  padding: 12px;
  font-weight: normal;

  a {
    font-weight: bold;
    color: $teal3;
  }
}
