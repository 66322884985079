@import "~arduino-sass/src/buttons";
@import "~arduino-sass/src/checkbox";
@import "~arduino-sass/src/inputfield";
@import "~arduino-sass/src/mixins";
@import "~arduino-sass/src/radiobutton";
@import "../../../styles/mixins";
@import "../../../styles/variables";

.delete-card-modal {
  overflow: auto !important;
  padding: 36px 130px !important;

  .title {
    font-weight: bold;
    font-size: 20px;
    color: black;
  }

  .description {
    font-size: 16px;
    line-height: 170%;
    margin-top: 12px;
  }

  .modal-store-button {
    padding: 5px 18px !important;
    font-weight: bold !important;
  }
}

.arduino-store-modal-overlay {
  // sass-lint:disable-line no-ids force-element-nesting
  background-color: rgb(0 0 0 / 60%) !important;
}
