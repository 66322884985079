/* stylelint-disable */
@import '~arduino-sass/src/buttons';
@import '~arduino-sass/src/mixins';
@import '~arduino-sass/src/variables';
@import '../../../styles/mixins';
@import '../../../styles/variables';

#root .aws-page {
  background-color: $clouds;
  height: calc(100vh - 65px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $jet;

  &__wrapper {
    max-height: 75vh;
    min-height: 75vh;
    height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar-track {
      border: 1px solid $clouds;
      padding: 2px 0;
      background-color: $clouds;
    }

    &::-webkit-scrollbar {
      width: 16px;
      padding: 0 2px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      //box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
      background-color: #7d7d7d66;
      border: 4px solid $clouds;
    }

    @include breakpoint-below($breakpoint-768) {
      max-height: unset;
    }
  }

  &__blob1 {
    position: absolute;
    bottom: 0;
    left: 50px;
  }

  &__blob2 {
    position: absolute;
    top: 50vh;
    right: 0px;
  }

  &__logo {
    width: 68px;
    height: 32px;
  }

  ul {
    padding: 32px 20px;
    list-style-type: unset;
    width: 100%;
    margin: 0;

    li {
      font-size: 12px;
      font-weight: 400;
      color: $gris;
      padding-bottom: 8px;
      text-align: left;
    }
  }

  &__content {
    padding: 32px 20px 24px 20px;
    background-color: $white;
    border-radius: 10px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
    z-index: 1;

    &--info {
      max-width: 350px;
      text-align: left;
    }
  }

  &__no-content {
    padding: 0;
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
  }

  &__description {
    font-size: 14px;
    font-weight: 400;
  }

  &__button {
    background-color: $teal3;
    padding: 8px 24px;
    border-radius: 32px;
    width: fit-content;
    color: $white;
    font-weight: 700;
    width: 100%;
    text-align: center;

    &--space {
      margin-top: 32px;
    }

    &--disabled {
      background-color: $asbestos ;
      opacity: 0.3 !important;
      color: $black;
    }
  }

  &__redeem {
    display: flex;
    align-items: center;
    height: 100vh;
    width: 100%;
    color: $jet;

    @include breakpoint-below($breakpoint-768) {
      flex-direction: column;
      margin-top: 20px;
    }
  }

  &__organizations {
    flex: 0 0 65%;
    height: 100%;
    padding: 40px 80px 22px 80px;
    text-align: left;

    @include breakpoint-below($breakpoint-768) {
      width: 100%;
      padding: 40px 16px 20px 16px;
      ;
    }

    &__title {
      font-size: 36px;
      font-weight: 400;
      padding-bottom: 30px;
    }
  }

  &__summary {
    flex: 0 0 35%;
    background-color: $white;
    padding: 40px;
    height: 100%;
    text-align: left;
    display: flex;
    gap: 8px;
    flex-direction: column;
    justify-content: space-between;

    @include breakpoint-below($breakpoint-768) {
      width: 100%;
      padding: 16px;
    }

    &__description {
      font-size: 16px;
      font-weight: 400;
      padding-bottom: 26px;
    }

    &__title {
      font-size: 28px;
      font-weight: 400;
      color: $black;
      padding-bottom: 8px;
    }

    &__data {
      padding: 32px 0;
      display: flex;
      flex-direction: column;
      gap: 16px;

    }

    hr {
      margin: 0;
      border-top: 1px solid $silver;
    }
  }

  &__info {
    font-size: 16px;
    display: flex;
    justify-content: space-between;
  }

  &__img {
    max-width: 500px;
  }
}

.organization {
  &__title {
    font-size: 14px;
    margin-bottom: 8px;
    margin-top: 0;
    font-weight: 400;
  }

  &--disabled {
    color: #95a5a6 !important;
    background-color: $feather !important;
    border-color: 1px solid $silver !important;
    cursor: unset;

    .organization__members,
    h5 {
      color: #95a5a6 !important;
    }
  }

  &--not-creable {
    color: #00818480 !important;
    background-color: $feather !important;
    border-color: 1px solid $silver !important;
    cursor: unset;
  }

  &__not-compatible {
    font-family: 'Roboto Mono';
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.1em;
    color: $gris;
    padding: 4px;
    border: 1px solid $gris;
    margin-left: 16px;
  }

  &__popover {
    width: 150px;
    font-weight: 400;
    font-size: 11px;
  }

  &__wrapper {
    border: 1px solid $silver;
    border-radius: 4px;
    background-color: white;
    padding: 16px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    &--right {
      margin-left: 8px;
    }

    &--reverse {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
  }

  &__create {
    color: $teal3;
    font-weight: 700;
  }

  &__members {
    font-size: 12px;
    color: $jet;
  }
}

.button {
  background-color: $teal3;
  padding: 8px 24px;
  border-radius: 32px;
  width: fit-content;
  color: $white;
  font-weight: 700;
  width: 100%;
  text-align: center;
}

.button-create {
  background-color: $teal3;
  padding: 8px 24px;
  border-radius: 32px;
  width: fit-content;
  color: $white;
  font-weight: 700;
  width: 100%;
  text-align: center;
}