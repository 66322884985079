@import "~arduino-sass/src/buttons";
@import "~arduino-sass/src/typography";
@import "../../../styles/mixins";
@import "../../../styles/variables";

#root .landing-page.create-landing-page {

  // sass-lint:disable-line no-ids force-element-nesting
  p {
    font-size: 16px;
  }

  .link {
    color: $teal3;
  }

  .landing-layout-40-40 {
    .jumbotron {
      max-height: unset;
    }
  }

  .jumbotron-right {
    height: auto;
    width: 600px;
    margin: 70px 90px;

    @include breakpoint-below($breakpoint-980) {
      display: block;
      margin: 90px;
      width: 100%;
    }

    @include breakpoint-below($breakpoint-580) {
      margin: 20px;
    }

    .jumbotron-category {
      @include font(roboto-mono, regular);

      letter-spacing: 0.12em;
    }

    .jumbotron-title {
      @include font(opensans, bold);

      font-size: 36px;
      letter-spacing: 0.01em;
      line-height: 150%;
      text-transform: none;
      hyphens: none;
    }

    .jumbotron-description {
      font-size: 20px;
      letter-spacing: 0.01em;
      line-height: 170%;
    }

    &.create-jumbotron {
      background-color: $white;
      border-radius: 2px;
      padding: 56px 48px;

      @include breakpoint-below($breakpoint-980) {
        background-color: transparent;
        padding: 0;
      }
    }
  }

  .choose-plan-container {
    background-color: $feather;
    display: flex;
    justify-content: center;
    padding: 100px 90px;

    @include breakpoint-below($breakpoint-580) {
      padding: 100px 45px;
    }
  }

  .code-and-program,
  .create-connected-objects {
    @include breakpoint-below($breakpoint-980) {
      flex-direction: column-reverse;
    }
  }

  .create-connected-objects,
  .code-and-program,
  .dashboards {
    overflow: hidden;
    position: relative;

    &.landing-layout-40-40 {
      .landing-layout-box {
        .landing-page-wrapper {
          @include breakpoint-below($breakpoint-580) {
            padding: 0 20px;
          }
        }
      }
    }

    .landing-layout-box-content {
      position: static;
    }

    ul {
      li {
        padding: 0 0 25px 25px;
        position: relative;

        &::before {
          background-color: $teal2;
          content: "";
          display: block;
          height: 7.64px;
          left: 2px;
          position: absolute;
          top: 8px;
          transform: rotate(45deg);
          width: 7.64px;
        }
      }
    }

    .small-text {
      display: inline-block;
      margin-left: 12px;
      text-indent: -12px;
    }

    .landing-layout-box {
      h2 {
        @include font(opensans, bold);

        color: $black;
        font-size: 28px;
        letter-spacing: 0.01em;
        text-transform: none;
      }

      .link {
        color: $teal3;
      }

      p {
        &:last-of-type {
          margin-bottom: 2em;
        }

        .link {
          font-size: 16px;
        }
      }

      &.image {
        padding: 0;

        .landing-page-wrapper {
          padding: 0;
        }

        img {
          @include breakpoint-below($breakpoint-980) {
            width: 100% !important;
            margin-top: 30px;
          }
        }
      }

      &:nth-child(1) {
        @include breakpoint-below($breakpoint-980) {
          padding-bottom: 0;
        }
      }

      &:nth-child(2) {
        @include breakpoint-below($breakpoint-980) {
          padding-bottom: 0;
        }
      }
    }
  }

  .create-connected-objects {
    margin-top: 60px;

    @include breakpoint-below($breakpoint-580) {
      margin-top: 0;
      margin-bottom: $layout-spacing-lg;
      min-height: 1240px;

      .landing-layout-box {
        padding: 0;
      }
    }

    .image {
      .landing-layout-box-content {
        padding-left: 0 !important;
        padding-right: 20% !important;
      }
    }
  }

  .code-and-program {
    margin-bottom: 80px;

    .image {
      .landing-layout-box-content {
        padding-left: 0 !important;
        padding-right: 20% !important;
      }
    }

    @include breakpoint-below($breakpoint-580) {
      .landing-layout-box {
        padding: 0;
      }
    }
  }

  .section-buttons {
    align-items: center;
    display: flex;

    @include breakpoint-below($breakpoint-580) {
      align-items: flex-start;
      flex-direction: column;

      .link {
        margin-top: 30px;
      }
    }
  }

  .table-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 80px 90px;

    @include breakpoint-below($breakpoint-580) {
      padding: 80px 45px;
    }

    .table-feature {
      display: table;
      max-width: 1236px;
      width: 100%;

      .table-heading {
        display: table-header-group;

        @include breakpoint-below($breakpoint-980) {
          display: none;
        }

        .table-head {
          @include font(opensans, bold);

          border-bottom: 1px solid $fog;
          color: $gris;
          display: table-cell;
          font-size: $font-size-sm;
          letter-spacing: 0.01em;
          padding: 16px 0;
          text-align: left;
          text-transform: uppercase;
          vertical-align: middle;
          width: 436px;

          &.features {
            padding-left: 15px;
          }

          &.free,
          &.entry,
          &.maker,
          &.maker-plus {
            border-bottom: 0;
            color: $white;
            text-align: center;
            width: 200px;
          }

          &.free {
            background-color: $teal0;
          }

          &.maker {
            background-color: $teal5;
          }

          &.entry {
            background-color: $teal2;
          }

          &.maker-plus {
            background-color: $onyx;
          }
        }
      }

      .table-body {
        display: table-row-group;

        &.free {
          .table-row {
            .table-cell {
              @include breakpoint-below($breakpoint-980) {

                &:nth-child(5),
                &:nth-child(4),
                &:nth-child(3) {
                  display: none;
                }
              }
            }
          }
        }

        &.entry {
          .table-row {
            .table-cell {
              @include breakpoint-below($breakpoint-980) {

                &:nth-child(2),
                &:nth-child(4),
                &:nth-child(5) {
                  display: none;
                }
              }
            }
          }
        }

        &.maker {
          .table-row {
            .table-cell {
              @include breakpoint-below($breakpoint-980) {

                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(5) {
                  display: none;
                }
              }
            }
          }
        }

        &.maker-plus {
          .table-row {
            .table-cell {
              @include breakpoint-below($breakpoint-980) {

                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4) {
                  display: none;
                }
              }
            }
          }
        }

        .table-row {
          display: table-row;

          &:nth-child(even) {
            background-color: $feather;
          }

          .table-cell {
            border-left: 1px solid $fog;
            color: $black;
            display: table-cell;
            font-size: 16px;
            padding: 16px 4px;
            text-align: left;
            vertical-align: middle;

            &.value {
              text-align: center;
              position: relative;

              .notes {
                padding-right: inherit;
                padding-left: inherit;
                left: 0;
                right: 0;
                font-size: 10px;
                position: absolute;
                bottom: 18px;
                color: $gris;
                opacity: 1;
                transition: all 0.1s ease;

                @include breakpoint-below($breakpoint-980) {
                  .hidden-on-mobile {
                    display: none;
                  }
                }

                &.collapsed {
                  transition: all 0.1s ease;
                  opacity: 0;
                }
              }
            }

            @include breakpoint-below($breakpoint-980) {
              border-left: 0;
              border-right: 0;
            }

            @include breakpoint-below(480px) {
              padding: 13px;
            }

            @include breakpoint-below(340px) {
              font-size: 16px;
              padding: 12px 6px;
            }

            .panel-header {
              cursor: pointer;
              position: relative;

              .panel-title {
                padding-left: 28px;
                font-size: 20px;
              }

              svg {
                left: 3px;
                position: absolute;
                top: 8px;
                transform: rotate(90deg);
                transition: transform $transition-timing ease-in-out;
              }
            }

            .is-open {
              svg {
                transform: rotate(180deg);
              }
            }

            .description {
              font-size: 14px;
              color: $gris;
              margin: 0;
              padding: 15px 28px 0;
              white-space: pre-line;

              p {
                margin-block-start: 10px;
                margin-block-end: 0;
                line-height: unset;
              }

              a {
                @extend %text-link;

                font-size: 14px;
                font-weight: 700;
                color: $teal3;
              }
            }

            &:last-child {
              border-right: 0;
            }

            &:first-child {
              border-left: 0;
              border-right: 0;
            }
          }
        }
      }
    }
  }

  .only-mobile-title {
    @include font(opensans, bold);

    color: $black;
    font-size: 36px;
    letter-spacing: 0.01em;
    text-transform: none;
  }

  .only-mobile-title,
  .only-mobile-button {
    display: none;
    margin-bottom: 25px;

    @include breakpoint-below($breakpoint-980) {
      display: flex;
      justify-content: center;
    }
  }

  .feature-mobile-button {
    @include font(opensans, bold);

    border-radius: 50px;
    color: $white;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    letter-spacing: 1.28px;
    line-height: normal;
    margin-left: 4px;
    padding: 4px 13px;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;

    &.free {
      background-color: $teal0;
      border: 1px solid $teal0;
      margin-left: 0;

      &.secondary {
        background-color: transparent;
        border: 1px solid $teal0;
        color: $teal0;
      }
    }

    &.entry {
      background-color: $teal2;
      border: 1px solid $teal2;

      &.secondary {
        background-color: transparent;
        border: 1px solid $teal2;
        color: $teal2;
      }
    }

    &.maker {
      background-color: $teal5;
      border: 1px solid $teal5;

      &.secondary {
        background-color: transparent;
        border: 1px solid $teal5;
        color: $teal5;
      }
    }

    &.maker-plus {
      background-color: $onyx;
      border: 1px solid $onyx;

      &.secondary {
        background-color: transparent;
        border: 1px solid $onyx;
        color: $onyx;
      }
    }
  }

  .pro-banner-feature {
    align-items: center;
    color: $black;
    display: flex;
    font-size: $font-size-sm;
    letter-spacing: 0.01em;
    justify-content: space-between;
    margin-top: 48px;
    max-width: 1236px;
    width: 100%;

    @include breakpoint-below($breakpoint-980) {
      display: none;
      flex-direction: column;

      &.show {
        display: flex;
      }
    }

    span {
      text-align: left;

      @include breakpoint-below($breakpoint-980) {
        text-align: center;
      }

      &.bold {
        font-weight: 700;
      }
    }

    a {
      @include ardu-store-button($teal3, $white);
      @include font(opensans, bold);

      font-size: $font-size-sm;
      margin-left: 76px;
      min-width: 150px;
      overflow: hidden;
      white-space: nowrap;

      @include breakpoint-below($breakpoint-980) {
        margin-left: 0;
        margin-top: 25px;
      }
    }
  }

  .banners {
    .landing-layout-box {
      &:first-child {
        margin-right: 15px;
      }

      &:last-child {
        margin-left: 15px;
      }

      &:nth-child(1) {
        .landing-page-wrapper {
          @include breakpoint-below($breakpoint-580) {
            padding: 0 20px;
          }
        }
      }

      &:nth-child(2) {
        .landing-page-wrapper {
          @include breakpoint-below($breakpoint-580) {
            padding: 0 20px;
          }
        }
      }

      @include breakpoint-below($breakpoint-980) {
        padding-top: 0;

        &:last-child {
          margin-left: 0;
        }

        &:first-child {
          margin-right: 0;
          padding-bottom: 0;
        }
      }
    }

    .landing-layout-box-content {
      @include font(opensans, bold);

      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 20px;
      display: block;
      height: 600px;
      padding: 70px;

      @include breakpoint-below($breakpoint-580) {
        height: 344px;
      }

      span {
        display: block;
      }

      .small-title {
        font-size: $font-size-sm;
        letter-spacing: 0.01em;
        text-transform: uppercase;
      }

      .banner-desc {
        font-size: 48px;
        line-height: 150%;
        margin-top: 20px;
      }

      @include breakpoint-below($breakpoint-580) {
        padding-left: 40px;

        .banner-desc {
          font-size: 36px;
        }
      }
    }
  }

  .teal-round-button {
    @include ardu-store-button($teal3, $white);

    margin-right: 36px;
    padding: 9px 28px;
  }

  .teal-round-button,
  .link {
    font-size: $font-size-sm;
    font-weight: 700;
    letter-spacing: 0.01em;
  }

  .help-center {
    background-color: $feather;
    padding: 90px;

    @include breakpoint-below($breakpoint-580) {
      padding: 30px;
    }

    .teal-round-button {
      margin-right: 0;
    }
  }

  .download-app {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;

    a {
      margin-right: 30px;
    }

    img {
      position: static;
      transform: none;
      width: 180px;
    }
  }

  .rebrand-banner {
    background-color: $sunflower;
    height: 35px;
    width: 100%;
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    z-index: 1000;

    .close-icon {
      cursor: pointer;
      margin-right: 10px;
      margin-left: auto;
    }

    p {
      margin-left: auto;
      font-size: 12px;

      a {
        font-weight: 700;
        color: $teal3;
      }
    }
  }
}