@import "~arduino-sass/src/mixins";
@import "~arduino-sass/src/buttons";
@import "~arduino-sass/src/variables";
@import "../../../../styles/mixins";
@import "../../../../styles/variables";

.redeem-voucher-form {
  border: 1px solid $fog;
  border-radius: 4px;
  padding: 24px;
  margin: 40px 0;

  h5 {
    color: $dust;
    font-size: 16px;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 170%; /* 27.2px */
    letter-spacing: 0.16px !important;
    margin: 0;
  }

  p {
    color: $jet;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%; /* 23.8px */
    letter-spacing: 0.14px !important;
    margin: 0;
  }

  &__input-container {
    display: flex;
    margin-top: 24px;
    column-gap: 24px;
    align-items: center;
  }

  &__input {
    flex-grow: 1;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold !important;
    background-color: $teal3 !important;
    font-size: 16px !important;
    color: $white !important;
    padding: 5px 20px !important;
    border-radius: 32px;
    margin-bottom: 18px !important;
    text-transform: uppercase;

    &:disabled {
      opacity: 0.5;
    }
  }

  &__loader {
    > div {
      > div {
        margin: 0 !important;
      }
    }
  }
}
