@import "~arduino-sass/src/buttons";
@import "~arduino-sass/src/variables";
@import "~arduino-sass/src/notifications";
@import "../../../../styles/variables";
@import "../../../../styles/mixins";

#root {
  .payment-spinner-wrapper {
    height: 20vh;
    position: relative;
  }

  .purchase-payment {
    margin-top: 28px;
    position: relative;

    .pay-button-container {
      display: flex;
      justify-content: flex-end;
      margin-top: $layout-spacing-lg;
      width: 100%;

      .pay-button {
        @include ardu-store-button($teal3, $white);
      }
    }

    .card-group {
      margin-right: 28px;

      .card-icon {
        margin-left: 0;
        margin-right: 5px;
        transition: opacity $transition-timing $transition-easing;
        width: 40px;
      }

      @include breakpoint-below(440px) {
        flex-direction: row;
        margin-bottom: -15px;
        margin-left: 40px;
        margin-right: 0;
        padding-right: 0;
      }
    }

    .back-button-payment {
      @include ardu-store-button($teal3, $white);
      @include size(42px);

      font-size: $layout-spacing-sm;
      margin-right: ($layout-spacing-xs * 2);
      padding: 8px;

      svg {
        fill: $carrot;
        left: 1px;
        position: absolute;
        top: 2px;
      }
    }

    .arduino-form {
      position: relative;

      .form-fields {
        &.hidden {
          opacity: 0;
        }
      }
    }

    .payment-method-title {
      color: #434f54;
      font-weight: 700;
      font-size: 12px;
      line-height: 170%;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      text-align: left;
    }

    &.arduino-form.indent {
      .form-fields {
        padding: 0 $layout-spacing-sm;

        .form-item {
          padding-left: 26px;
        }
      }
    }

    .ardu-radio {
      cursor: pointer;
      margin: 0 !important;

      .custom-radio {
        margin: 0;
      }

      h4 {
        @include font(opensans, regular);

        line-height: 24px;
        margin: 0;
        padding-left: 17px;
      }
    }

    .absolute-spinner-container {
      background-color: $feather;
      bottom: 0;
      height: 100%;
      z-index: 1;
    }

    .error-notification {
      @extend %error-notification;

      margin: $layout-spacing-sm $layout-spacing-xs 0;
      width: 100%;
    }

    .terms-wrapper {
      display: flex;
      justify-content: flex-end;
      margin-top: 24px;
      min-height: 46px;
      width: 100%;

      .checkbox-wrapper {
        display: flex;
        flex-direction: column;

        .terms-link {
          text-transform: unset;
        }

        .error-message {
          @include font(opensans, regular);

          color: $notifyerror;
          font-size: 16px;
          letter-spacing: 0.7px;
          padding-top: 5px;
        }
      }
    }

    .credit-card-list {
      width: 100%;
    }

    .use-new-card-section {
      width: 100%;
      margin-top: 24px;

      .header-panel {
        align-items: center;
        display: flex;
        font-size: 20px;
        height: 70px;
      }

      &.expanded {
        .use-new-card-form {
          border: 1px solid $teal2;
          border-radius: 7px;
          max-height: 400px;
          padding-bottom: 30px;
          transition: max-height 0.25s ease-in;

          .ardu-radio {
            display: flex;
            padding-left: 12px;
          }

          @include breakpoint-below($breakpoint-580) {
            max-height: 580px;
          }
        }

        .use-new-card {
          display: none;
        }
      }

      .use-new-card {
        align-items: center;
        background-color: $white;
        color: #000 !important;
        cursor: pointer;
        display: flex;
        height: 50px;
        position: relative;
        width: 100%;

        &::after {
          color: #000;
          content: "\002B";
          font-size: 38px;
          left: 13px;
          position: absolute;
          top: 0;
        }

        h4 {
          @include font(opensans, regular);

          color: #000;
          line-height: 24px;
          margin: 0;
          padding-left: $layout-spacing-lg;
        }
      }

      .use-new-card-form {
        background-color: $white;
        border: 1px solid $fog;
        border-radius: 7px;
        max-height: 70px;
        overflow: hidden;
        width: 100%;

        .ardu-radio {
          display: none;
        }

        .submit-button-container {
          display: none;
        }

        .form-group {
          margin: 0 auto;
          width: calc(100% - 80px);
          text-align: left;

          .form-item {
            padding-left: 0;
          }
        }

        &.no-cards {
          background-color: transparent;
          border: 0;
          padding-bottom: 0;

          .header-panel {
            display: none;
          }

          .form-group {
            width: 100%;
          }

          .card-group {
            margin-right: -12px;

            @include breakpoint-below(440px) {
              margin-left: 0;
            }
          }
        }
      }
    }
  }

  .payment-information-billing {
    display: flex;
    flex-direction: column;
  }

  .main-title {
    font-size: 12px;
    color: #434f54;
    text-transform: uppercase;
    font-weight: 700;
    text-align: left;
    margin-bottom: 8px;
    width: 100%;
  }

  .billing-wrapper {
    width: 100%;
  }

  .billing-form {
    display: flex;
    background-color: white;
    border: 1px solid $smoke;
    border-radius: 4px;
    padding: 16px;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .billing-form-text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
    }

    .delete-button {
      font-size: 16px;
      text-transform: uppercase;
      color: $teal3;
      font-weight: bold;

      &:hover {
        cursor: pointer;
        color: $teal1;
      }
    }
  }
}
