@import "~arduino-sass/src/buttons";
@import "~arduino-sass/src/checkbox";
@import "~arduino-sass/src/inputfield";
@import "~arduino-sass/src/mixins";
@import "~arduino-sass/src/radiobutton";
@import "../../../styles/mixins";
@import "../../../styles/variables";

.credit-card-list {
  .credit-card-list-element {
    align-items: center;
    color: #434f54;
    background-color: $white;
    border: 1px solid $fog;
    border-radius: 7px;
    display: flex;
    font-size: 20px;
    height: 70px;
    margin-bottom: $layout-spacing-xs;

    @include breakpoint-below($breakpoint-580) {
      height: 130px;
    }

    &.selected {
      border-color: $teal2;
    }

    &.disabled {
      cursor: default;
      pointer-events: none;
      background-color: $white !important;
      border: 1px solid $fog !important;
    }

    .credit-card-info {
      align-items: center;
      display: flex;
      padding-left: 15px;
      width: 100%;

      svg {
        height: 35px;
      }

      .expiry-container {
        padding-left: $layout-spacing-xs;

        @include breakpoint-below($breakpoint-580) {
          padding-left: 0;
        }
      }

      .spacer {
        flex: 1;
      }
    }

    .ardu-radio {
      cursor: pointer;
      margin: 0 !important;
      padding-left: 12px;

      .custom-radio {
        margin: 0;
      }
    }

    .delete-button {
      font-size: 16px !important;
      text-transform: uppercase !important;
      color: $teal3 !important;
      font-weight: bold !important;
      margin-right: 24px !important;

      &:hover {
        cursor: pointer;
        color: $teal1 !important;
      }
    }
  }
}
