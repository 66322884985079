@import "~arduino-sass/src/variables";

$brand-color-main: #312783;
$brand-white: #eee;
$brand-black: #111;
$stardust: #def154;
$text-color-main: $brand-black;
$text-color-alt: $brand-white;
$layout-spacing-lg: 50px;
$layout-spacing: 45px;
$layout-spacing-sm: 25px;
$layout-spacing-xs: 10px;
$layout-spacing-xxs: 5px;

// Breakpoints
$breakpoint-1380: 1380px;
$breakpoint-1200: 1200px;
$breakpoint-980: 980px;
$breakpoint-768: 768px;
$breakpoint-650: 650px;
$breakpoint-580: 580px;

// Page
$page-max-width: 1440px;
$page-padding-1200: $layout-spacing * 2;
$page-border-top: 2px solid $smoke;

// App bar (Header Footer)
$app-bar-height: 50px;
$app-bar-z-index: 10000;
$app-bar-transition-function: ease-out;

// Typography
$font-size-base: 18px;
$font-size-xl: 32px;
$font-size-lg: 26px;
$font-size-md: $font-size-base;
$font-size-sm: 16px;
$font-size-xs: 12px;
$letter-spacing: .75px;
$letter-spacing-big: 2.85px;

// Common cards variables
$card-width: $layout-spacing * 8;
$card-border: 1px solid $fog;
$card-border-radius: 6px;
$card-border-radius-inner: 6px;

// Inputs
$input-font-size: 20px;
$input-color: #000;
$input-placeholder-color: rgba($input-color, .5);
$input-padding: 12px 18px;

// Transitions
$transition-timing: .2s;
$transition-easing: ease-in-out;

// Landing pages
$landing-page-font-size: 20px;
