@import "~arduino-sass/src/buttons";
@import "~arduino-sass/src/variables";
@import "~arduino-sass/src/notifications";
@import "../../../styles/variables";
@import "../../../styles/mixins";

#root {
  .credit-card-add-form {
    position: relative;

    &.arduino-form {
      @include breakpoint-below(480px) {
        padding-bottom: 15px;
      }
    }

    .form-label {
      padding: 0 !important;
    }

    form .submit-button-container {
      display: flex;
      justify-content: flex-end;
      margin-top: $layout-spacing-sm;
      padding: $layout-spacing-xs;
      width: 100%;

      @include breakpoint-below($breakpoint-768) {
        flex-direction: column;
      }
    }

    .button-container {
      display: flex;
      justify-content: flex-end;
      padding: 20px 40px 10px $layout-spacing-xs;
      width: 100%;
      align-items: center;

      .submit-button {
        @include ardu-store-button($teal3, $white);

        margin: 0;
      }

      .reset-button {
        @include ardu-store-button(transparent, $teal3);

        font-weight: bold;
      }
    }

    .submit-button {
      @include ardu-store-button($carrot, $white);

      @include breakpoint-below($breakpoint-768) {
        margin-top: 15px;
      }
    }

    .reset-button {
      @include ardu-store-button($white, $teal3);

      font-weight: bold;
    }

    .reset-button,
    .submit-button {
      @include font(opensans, regular);

      font-size: 13px;
      height: 37px;
      letter-spacing: 1.39px;
      margin-left: 15px;

      &.hidden {
        height: 0;
        pointer-events: none;
        visibility: hidden;
      }
    }

    .card-group {
      align-self: flex-end;
      display: flex;
      flex-direction: row-reverse;
      margin-bottom: -40px;
      padding-right: 8px;

      .card-icon {
        margin-left: $layout-spacing-xs;
        transition: opacity $transition-timing $transition-easing;
        width: 40px;
      }
    }
  }
}
