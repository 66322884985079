@import "~arduino-sass/src/buttons";
@import "~arduino-sass/src/mixins";
@import "~arduino-sass/src/variables";
@import "../../../../styles/mixins";
@import "../../../../styles/variables";

#root .my-subscriptions-tab {
  // sass-lint:disable-line no-ids force-element-nesting
  position: relative;
  text-align: center;

  .subscription-cards-list {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;

    .plans-title {
      font-size: 28px;
      line-height: 170%;
      color: #000;
      margin-bottom: 40px;
      width: 100%;
      text-align: left;
    }
  }

  .stack-card {
    &.subscription-card-free {
      .main {
        @include breakpoint-below($breakpoint-980) {
          padding-bottom: 0;
        }
      }
    }

    .stack-card-title {
      align-items: baseline;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      @include breakpoint-below($breakpoint-768) {
        flex-direction: column;
        margin-bottom: $layout-spacing-lg;

        h4 {
          margin-top: $layout-spacing-xs;
        }
      }
    }

    .trial-amount {
      @include font(opensans, regular);

      text-transform: uppercase;
    }

    .past-due {
      @include font(opensans, regular);

      text-transform: uppercase;

      svg {
        color: $notifyerror;
        height: 24px;
        margin-right: 8px;
        width: 24px;
      }
    }

    .past-due-banner {
      @include past-due-banner();
    }

    .enterprise-banner {
      @include enterprise-banner();

      margin-bottom: 12px;
    }

    .trial-info {
      flex: 1 1 100%;
      font-size: 18px;
      letter-spacing: 1.2px;
      text-align: right;
    }

    .amount {
      text-align: right;

      @include breakpoint-below($breakpoint-580) {
        text-align: left;
      }
    }

    .credit-card-info {
      flex: 1 1 auto;
    }

    .subscription-section {
      margin-bottom: $layout-spacing;

      &:last-child {
        margin-bottom: 0;
      }

      h5 {
        @include font(opensans, regular);

        margin-bottom: $layout-spacing-xs;
        text-transform: uppercase;
      }

      p {
        @include font(opensans, regular);

        margin-top: 0;
      }
    }

    .change-card-button {
      @include ardu-store-button($white, $teal3);

      margin-left: auto;
      border: 2px solid $teal3;

      @include breakpoint-below($breakpoint-768) {
        width: 100%;
      }
    }

    .features-container {
      flex: 1;
      width: 100%;
    }

    .features {
      column-count: 2;
      margin: 0;

      li {
        font-size: 20px;
        letter-spacing: $letter-spacing;
        line-height: $layout-spacing;
      }

      @include breakpoint-below($breakpoint-768) {
        column-count: 1;
      }
    }
  }

  .invoices-list {
    background-color: $feather;
    border: 1px solid $fog;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    margin: 0;
    max-width: 100%;
    overflow: hidden;
    text-align: left;
    width: 100%;
  }

  .invoice-info {
    @include font(opensans, regular);

    border-bottom: 1px solid $fog;
    display: flex;
    flex: 1 1 auto;
    font-size: 20px;
    justify-content: space-between;
    line-height: 170%;
    padding: 22px;
    color: $dust;

    &:last-child {
      border-bottom: 0;
    }

    span {
      align-items: center;
      display: flex;
      flex: 1 1 auto;
    }

    svg {
      color: $darknotifyerror;
      margin-left: 8px;
    }

    .invoice-plan {
      flex: 0 0 40%;
    }

    .invoice-amount {
      flex: 0 0 auto;
    }

    .invoice-amount,
    .invoice-date {
      @include font(opensans, regular);
    }

    @include breakpoint-below($breakpoint-980) {
      flex-direction: column;

      span {
        margin-bottom: $layout-spacing-xs;
      }
    }
  }

  .month {
    background-color: $white;
    padding: 11px 22px;
    border-bottom: 1px solid $fog;

    h6 {
      margin: 0;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 12px;
      line-height: 170%;
      letter-spacing: 0.01em;
      color: #434f54;
    }
  }

  .billing-info-stack-card {
    .change-billing-button {
      @include ardu-store-button($fog, $black);

      @include breakpoint-above($breakpoint-980) {
        @include position(absolute, 15px 15px null null);
      }

      @include breakpoint-below($breakpoint-980) {
        margin-top: 15px;
        width: 100%;
      }
    }

    .well-content {
      font-size: 16px;
      min-height: 70px;
      overflow: hidden;
    }

    .side {
      align-items: flex-start;

      h6 {
        margin: 0 0 9px;
        text-align: left;
      }

      a {
        @extend %text-link;

        font-weight: bold;
      }
    }
  }
}
