@import "~arduino-sass/src/buttons";
@import "~arduino-sass/src/variables";
@import "../../../../styles/variables";
@import "../../../../styles/mixins";

#root {
  .details-section-container {
    .details-spinner-wrapper {
      height: 210px;
      position: relative;

      .absolute-spinner-container {
        background-color: $white;
      }
    }
  }

  .coupon-section {
    display: flex;
  }

  .product-details {
    display: flex;
    flex-direction: column;
    height: 100%;

    @include font(opensans, regular);

    $price-border: 1px solid $fog;

    letter-spacing: $letter-spacing;
    min-height: 650px;

    &__button {
      background-color: transparent;
      position: fixed;
      bottom: 0;
      width: 33%;
      max-width: 486px;

      @include breakpoint-below($breakpoint-1200) {
        position: static;
        width: auto;
        max-width: 100%;
      }
    }

    h3 {
      @include font(opensans, regular);

      text-align: left;
    }

    .title {
      margin-bottom: $layout-spacing-xs;
      margin-top: 0;
      text-align: left;
    }

    .sub-title {
      margin-bottom: 24px;
      margin-top: 0;
      text-align: left;
      color: $charcoal;
      font-size: 16px;
      letter-spacing: 0.01em;
    }

    .description {
      border-bottom: $price-border;
      padding-bottom: $layout-spacing-sm;
      text-align: left;
    }

    .price-section {
      padding-right: 8px;

      .price-title {
        display: flex;
        height: 35px;
        justify-content: space-between;
        letter-spacing: 1.3px;
        padding: $layout-spacing-xs 0;
        text-transform: uppercase;

        .value {
          color: #7f8c8d;
          font-size: 14px;
        }
      }

      .discount-title {
        display: flex;
        height: 35px;
        justify-content: space-between;
        letter-spacing: 1.3px;
        padding: 8px;
        background-color: #e8f5f3;
        border-radius: 4px;
        font-weight: bold;

        .value {
          color: #000;
          font-size: 16px;
          font-weight: 400;
        }
      }

      .detail-section {
        font-size: 16px;
        padding: $layout-spacing-xs 0;

        .price-row {
          display: flex;
          justify-content: space-between;
        }

        .price-margin-top {
          margin-top: $layout-spacing-sm;
        }

        .price-row-spacing {
          margin: 25px 0;
        }

        .price {
          .label {
            @include font(opensans, regular);

            text-align: left;

            @include breakpoint-between(1201px, 1300px) {
              max-width: 250px;
            }

            @include breakpoint-below(500px) {
              max-width: 170px;
            }
          }

          .value {
            letter-spacing: 1.3px;
          }
        }

        .price-description {
          margin-bottom: $layout-spacing-sm;
          margin-top: $layout-spacing-xxs;
          text-align: left;
        }

        .tax {
          margin: $layout-spacing-sm 0;
        }
      }
    }

    .payment {
      @include font(opensans, regular);

      align-items: center;
      display: flex;
      font-size: 20px;
      justify-content: space-between;
      letter-spacing: 0.01em;
      padding: 15px 0;
      position: relative;
      flex-wrap: wrap;
      border-top: $price-border;
      text-transform: capitalize;

      .next-payment {
        font-size: 12px;
        color: $gris;
        flex: 100%;
        text-align: left;
        margin-top: 15px;
      }

      .free-trial-tooltip,
      .discount-tooltip {
        background-color: $notifysuccess;
        border-radius: $layout-spacing-xxs;
        bottom: -$layout-spacing;
        color: $white;
        font-size: $font-size-base;
        padding: $layout-spacing-xs;
        position: absolute;
        right: 0;
        text-transform: uppercase;

        &::after {
          border-bottom: 8px solid $notifysuccess;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          content: "";
          height: 0;
          position: absolute;
          right: 16px;
          top: -6px;
          width: 0;
        }
      }

      .value {
        font-size: 28px;
        letter-spacing: 0.01em;
        font-weight: bold;

        &.canceled {
          text-decoration: line-through;
        }
      }
    }

    .arduino-form {
      margin-bottom: 0;
    }

    .form-fields {
      .apply-button-container {
        align-items: flex-end;
        display: flex;
        padding: 16px 0 16px 16px;

        .apply-button {
          @extend %text-link;

          text-transform: uppercase;
          font-weight: bold;
        }
      }

      .input-group {
        position: relative;

        .check-icon,
        .close-icon {
          position: absolute;
          right: 15px;
          top: 18px;
          visibility: hidden;
        }

        .check-icon {
          right: 10px;
        }

        .busy-spinner {
          align-self: center;
          height: 18px;
          position: absolute;
          right: 22px;
          top: 8px;
          visibility: hidden;
          width: 18px;

          > div {
            @include spinner($silver, $white, 35px);
          }
        }

        &.error {
          .close-icon {
            visibility: visible;
          }
        }

        &.valid {
          .check-icon {
            visibility: visible;
          }
        }

        &.progress {
          .busy-spinner {
            visibility: visible;
          }

          .check-icon,
          .close-icon {
            visibility: hidden;
          }
        }
      }

      .coupon-input {
        padding-right: 35px;

        &.valid {
          border: 1px solid $teal2;
        }
      }
    }

    .discount-code {
      font-weight: bold;
      font-size: 14px;
      color: $teal3;
      text-decoration: underline;
      text-align: left;

      &:hover {
        cursor: pointer;
      }
    }

    .vat-disclaimer {
      border: 1px solid $notifyerror;
      border-radius: 4px;
      color: $black;
      font-size: 12px;
      background-color: #f9e9e6;
      margin: 10px 0;
      padding: 4px;
      text-align: left;

      & + .free-trial-banner {
        margin-top: $layout-spacing-lg;
      }
    }

    .free-trial-banner {
      background-color: $clouds;
      color: $black;
      font-size: 16px;
      letter-spacing: 0.7px;
      line-height: 22px;
      margin-bottom: 20px;
      margin-top: $layout-spacing-lg * 2;
      padding: $layout-spacing;
      text-align: left;

      .title {
        @include font(opensans, regular);

        margin-bottom: $layout-spacing / 2;
      }

      .description {
        border-bottom: 0;
        padding-bottom: 0;
      }
    }

    .credit-banner {
      width: 100%;
      padding: 8px;
      font-size: 14px;
      text-align: left;
      line-height: 170%;
      letter-spacing: 0.01em;
      background-color: $feather;
      border-radius: 4px;
      margin: 16px 0;
    }
  }

  .discount-message {
    font-weight: bold;
    font-size: 12px;
    line-height: 170%;
    color: #1da086;
    display: flex;
    align-items: center;
    width: 100%;

    .discount-text {
      margin-left: 4px;
      font-weight: 700;
    }
  }

  .discount-error {
    font-weight: bold;
    font-size: 12px;
    line-height: 170%;
    color: #da5b4a;
    display: flex;
    align-items: center;
    width: 100%;

    .discount-text {
      margin-left: 4px;
      font-weight: 700;
    }
  }

  .summary-button {
    background-color: $teal3;
    border-radius: 32px;
    padding: 8px 28px;
    width: 100%;
    display: flex;
    justify-content: center;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 12px;
  }

  .hr-style {
    height: 1px;
    background-color: $silver;
    border: none;
    margin-bottom: 0 !important;
  }

  .cta-button {
    @include ardu-store-button($teal3, $white);

    font-size: 14px;
    margin-top: auto;
    margin-bottom: 36px;
    font-weight: 700;
    width: 100%;

    @include breakpoint-below($breakpoint-580) {
      margin-left: 0;
    }
  }

  .disabled {
    background-color: #7fc0c1;
    border-color: #7fc0c1;
  }

  .sub-price {
    color: #4e5b61;
    font-size: 16px;
    font-weight: 400;
    margin-left: 16px;
  }
}
