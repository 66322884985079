/* stylelint-disable */
@import '~arduino-sass/src/buttons';
@import '~arduino-sass/src/checkbox';
@import '~arduino-sass/src/inputfield';
@import '~arduino-sass/src/mixins';
@import '~arduino-sass/src/radiobutton';

* {
  box-sizing: inherit;

  &::before,
  &::after {
    box-sizing: inherit;
  }
}

img,
a {
  border: 0; // fix for IE10
}

.ReactModal__Html--open,
.ReactModal__Body--open {
  overflow: hidden;
}

body {
  a {
    color: initial;
    text-decoration: none;
  }
}

#root {
  // sass-lint:disable-line no-ids force-element-nesting
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $dust;
    font-weight: normal;
    letter-spacing: $letter-spacing;

    &.gold {
      color: $gold;
    }
  }

  h1 {
    @include font(opensans, regular);

    font-size: 40px;
    letter-spacing: $letter-spacing-big;
    margin: $layout-spacing 0;
  }

  h2 {
    @include font(roboto-mono, bold);

    color: $gold;
    font-size: 32px;
    letter-spacing: 3px;
    margin: $layout-spacing-sm 0;
    text-transform: uppercase;
  }

  h3 {
    @include font(opensans, regular);

    color: $black;
    font-size: 30px;
    margin: 30px 0;
  }

  h6 {
    @include font(opensans, regular);

    font-size: 13px;
  }

  p {
    letter-spacing: $letter-spacing;
  }

  .link {
    @extend %text-link;
  }

  // https://gist.github.com/MoOx/9137295
  button {
    // Corrects font smoothing for webkit
    -moz-osx-font-smoothing: inherit;
    -webkit-font-smoothing: inherit;

    // Corrects inability to style clickable `input` types in iOS
    appearance: none;
    background: transparent;
    border: 0;

    // inherit font & color from ancestor
    color: inherit;
    cursor: pointer;
    font: inherit;

    // Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+.
    line-height: normal;
    margin: 0;
    //outline: none;
    overflow: visible;
    padding: 0;
    text-align: inherit;
    width: auto;
  }

  .link-button {
    @extend %text-button;
  }

  .button {
    @include ardu-button($teal2, $teal5);

    font-size: 18px;
  }

  .loader-container {
    display: flex;
    justify-content: center;
    margin-top: 25vh;
  }

  .loader {
    @include spinner($teal2, $feather, 12em);
  }

  input[type='text'],
  input[type='email'],
  textarea,
  .stripe-input {
    @extend %ardu-input;

    border: 1px solid $silver;
    border-radius: 3px;
    color: $input-color;
    font-size: $input-font-size;
    letter-spacing: 0.7px;
    margin-bottom: 0;
    padding: $input-padding;

    &::input-placeholder {
      /* Chrome/Opera/Safari */
      color: $input-placeholder-color;
    }

    &::placeholder {
      /* Firefox 19+ */
      color: $input-placeholder-color;
    }

    &:input-placeholder {
      /* IE 10+ */
      color: $input-placeholder-color;
    }

    &:placeholder {
      /* Firefox 18- */
      color: $input-placeholder-color;
    }

    &.disabled {
      background-color: $fog;
      border: 1px solid $fog;
      pointer-events: none;

      .__PrivateStripeElement {
        color: $dust;
      }
    }

    &.error {
      border-right-width: 1px;
    }
  }

  textarea {
    resize: none;
  }

  select {
    @extend %ardu-select;

    border: 1px solid $silver;
    border-radius: 3px;
    color: $input-color;
    font-size: $input-font-size;
    letter-spacing: 0.7px;
    margin-bottom: 0;
    padding: $input-padding;
    padding-right: 40px;

    .default-select-value {
      @include font(opensans, regular-italic, style);
    }

    &::input-placeholder {
      /* Chrome/Opera/Safari */
      color: $input-placeholder-color;
    }

    &::placeholder {
      /* Firefox 19+ */
      color: $input-placeholder-color;
    }

    &:input-placeholder {
      /* IE 10+ */
      color: $input-placeholder-color;
    }

    &:placeholder {
      /* Firefox 18- */
      color: $input-placeholder-color;
    }

    &.disabled {
      background-color: $fog;
      border: 1px solid $fog;
      pointer-events: none;

      .__PrivateStripeElement {
        color: $dust;
      }
    }

    &.error {
      border-right-width: 1px;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  .ardu-checkbox {
    @extend %ardu-checkbox;
  }

  .ardu-radio {
    @extend %ardu-radio;

    //margin-bottom: $layout-spacing-xs;
    cursor: pointer;

    .custom-radio {
      margin-right: 12px !important;

      &::before {
        @include size(14px);

        left: 3px;
        top: 3px;
      }
    }
  }
}

//Custom stripe input for payment page
.__PrivateStripeElement {
  height: 25px !important;
  transform: translateY(10%) !important;
  font-size: 20px !important;
  background-color: red !important;
}

.stripe-input .StripeElement .StripeElement--empty {
  height: 51px !important;
  font-size: 20px !important;
}

.InputElement.is-empty.Input.Input--empty {
  font-size: 20px !important;
}

.CardNumberField .CardNumberField--ltr {
  font-size: 20px !important;
}

.ElementsApp.ElementsApp.InputElement {
  padding-bottom: 5px !important;
}
