/* stylelint-disable */
@import '~arduino-sass/src/buttons';
@import '~arduino-sass/src/radiobutton';
@import '~arduino-sass/src/variables';
@import '../../../styles/mixins';
@import '../../../styles/variables';

#root {
  .billing-info-form {
    position: relative;
    padding: 0 0 24px;

    .billing-form-fields {
      background-color: white;
      border: 1px solid $fog;
      padding: 8px 24px;
    }

    &.arduino-form {
      @include breakpoint-below($breakpoint-580) {
        padding-bottom: 15px;
      }

      .certificate-upload {
        align-items: flex-start;
        width: 100%;

        @include breakpoint-below($breakpoint-580) {
          align-items: initial;
          flex-direction: column;
        }

        .label {
          position: relative;

          .update-message {
            position: absolute;
            right: 0;
            text-align: right;
            top: 25px;
            width: 250px;

            @include breakpoint-below($breakpoint-580) {
              position: relative;
              text-align: center;
              width: 100%;
            }
          }
        }

        input {
          display: none;
        }

        .button-upload {
          @include font(opensans, bold);

          @extend %text-link;

          display: flex;
          font-size: 14px;
          letter-spacing: 2px;
          justify-content: flex-end;
          border: 2px solid $teal3;
          border-radius: 32px;
          padding: 2px 16px;
          color: $teal3;
          float: right;
          width: 92 px;

          @include breakpoint-below($breakpoint-580) {
            display: block;
            margin-top: 25px;
            text-align: center;
          }
        }

        .tax-desc {
          display: flex;
          flex-direction: column;
          height: unset;
          margin: 0;
        }

        .remove-button {
          align-items: center;
          cursor: pointer;
          margin-left: 8px;
          float: right;

          &:focus {
            outline: none;
          }
        }

        .uploaded-file-name {
          align-items: center;
          background-color: $white;
          border: 1px solid $fog;
          border-radius: 6px;
          display: none;
          height: 35px;
          margin-top: 0;
          padding: 0 12px;
          position: relative;

          @include breakpoint-below($breakpoint-580) {
            justify-content: space-between;
            margin-top: 25px;
          }

          .file-name {
            display: inline-block;
            max-width: 300px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .error-message {
          min-width: 200px;
          position: absolute;
          right: 0;
          top: 35px;
          text-align: right;

          @include breakpoint-below($breakpoint-580) {
            justify-content: space-between;
            text-align: center;
            width: 100%;
          }
        }

        .certificate-error {
          font-size: 12px;
          line-height: 170%;
          letter-spacing: 0.01em;
          font-weight: 700;
          color: #da5b4a;
          display: flex;
          align-items: center;
        }

        .certificate-information {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .certificate-uploaded {
          font-size: 12px;
          line-height: 170%;
          letter-spacing: 0.01em;
          color: #1da086;
          align-items: center;
          flex: 0 0 50%;
        }

        .max-size {
          flex: 0 0 50%;
          font-size: 12px;
          color: $jet;
          float: left;
          text-align: end;
        }

        .certificate-text {
          margin-left: 4px;
          font-weight: 700;
        }

        .custom-input {
          background-color: #fff;
          padding: 10px 12px;
          width: 100%;
          border: 1px solid #c9d2d2;
          border-radius: 3px;
          height: 51px;

          .file-name {
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            vertical-align: middle;
          }

          .button-upload {
            &:hover {
              color: $teal3;
            }
          }
        }

        &.active {
          .button-upload {
            display: none;
          }

          .uploaded-file-name {
            display: flex;
          }

          .update-message {
            display: none;
          }
        }
      }
    }

    .billing-type-wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;

      @include breakpoint-below($breakpoint-580) {
        flex-direction: column;
      }

      li {
        align-items: center;
        background-color: $white;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        width: calc(33.333% - 10px);

        @include breakpoint-below($breakpoint-580) {
          margin-bottom: 10px;
          width: 100%;
        }

        &.active {
          box-shadow: 0 3px 3px -2px rgb(0 0 0 / 20%), 0 3px 4px 0 rgb(0 0 0 / 14%), 0 1px 8px 0 rgb(0 0 0 / 12%);
        }

        .type-wrapper {
          align-items: center;
          display: flex;
          flex-direction: column;
          height: 100%;
          padding: 25px;

          @include breakpoint-below($breakpoint-580) {
            flex-direction: row-reverse;
            width: 100%;
          }

          .spacer {
            flex: 1;

            @include breakpoint-below($breakpoint-580) {
              flex: 0;
            }
          }
        }

        label {
          @include font(opensans, bold);

          cursor: pointer;
          text-align: center;

          &.billing-type-description {
            @include font(opensans, regular);

            margin: 18px;
          }
        }

        .billing-labels {
          display: flex;
          flex-direction: column;

          @include breakpoint-below($breakpoint-580) {
            flex: 1;
          }
        }
      }
    }

    .billing-type-radio {
      @extend %ardu-radio;

      margin-left: 25px;
    }

    .submit-buttons-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-bottom: $layout-spacing;
      width: 100%;
      height: 50px;
      align-items: flex-end;

      @include breakpoint-below($breakpoint-580) {
        flex-direction: column;
      }
    }

    .submit-button {
      @include ardu-store-button($teal3, $white);

      font-size: 14px;
      margin-left: 20px;
      margin-top: $layout-spacing-lg;
      font-weight: 700;

      @include breakpoint-below($breakpoint-580) {
        margin-left: 0;
      }
    }

    .external-submit-button {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }

    .unedit-button {
      @include ardu-store-button(transparent, $teal3);

      font-size: 14px;
      margin-left: 20px;
      margin-top: $layout-spacing-lg;
      font-weight: 700;

      @include breakpoint-below($breakpoint-580) {
        margin-left: 0;
      }
    }

    .submit-edit-button {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      margin-bottom: 24px;

      .secondary-button {
        @include ardu-store-button($white, $teal3);

        font-size: 14px;
        margin-left: 20px;
        margin-top: $layout-spacing-lg;
        font-weight: 700;

        @include breakpoint-below($breakpoint-580) {
          margin-left: 0;
        }
      }
    }
  }

  .billing-container {
    width: 100%;
    margin-bottom: 24px;

    .billing-title {
      font-size: 12px;
      color: #434f54;
      text-transform: uppercase;
      font-weight: 700;
      margin-bottom: 8px;
    }
  }

  .billing-view {
    text-align: left;
    font-size: 20px;

    @include breakpoint-below($breakpoint-580) {
      font-size: 16px;
    }

    .change-billing-button {
      @extend %text-link;
    }
  }

  .billing-form {
    display: flex;
    background-color: white;
    border: 1px solid $smoke;
    border-radius: 4px;
    padding: 16px;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .billing-form-text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      min-height: 62px;
    }

    .delete-button {
      font-size: 16px;
      text-transform: uppercase;
      color: $teal3;
      font-weight: bold;

      &:hover {
        cursor: pointer;
        color: $teal1;
      }
    }

    .change-button {
      @include ardu-store-button($white, $teal3);

      padding: 9px 18px;
      border: 2px solid $teal3;
    }
  }

  .form-label {
    margin-top: 0 !important;
  }

  .submit-button {
    @include ardu-store-button($teal3, $white);

    font-size: 14px;
    margin-left: 20px;
    margin-top: $layout-spacing-lg;
    font-weight: 700;
    float: right !important;

    @include breakpoint-below($breakpoint-580) {
      margin-left: 0;
    }
  }

  .billing-wrapper {
    background-color: white;
    border: 1px solid #3fae98;
    border-radius: 4px;
  }
}
