@import "~arduino-sass/src/buttons";
@import "~arduino-sass/src/radiobutton";
@import "~arduino-sass/src/variables";
@import "~arduino-sass/src/spacing";
@import "~arduino-sass/src/mixins";
@import "../../../styles/mixins";
@import "../../../styles/variables";

#root {
  .form-fields {
    .form-item {
      margin-top: 0 !important;
    }

    .form-label {
      margin-top: 0 !important;
    }

    .plan {
      display: flex;
      background: white;
      border-radius: $space-xx-small;
      padding: 24px $space-medium $space-medium;
      flex-direction: column;
      width: 100%;

      .plan-title {
        font-size: $space-medium;
        color: $dust;
        line-height: 170%;
        letter-spacing: 0.01em;
        margin-bottom: $space-medium;
      }

      .plan-seats {
        display: flex;
        margin-bottom: 32px;

        .box {
          background-color: $feather;
          border-radius: $space-xx-small;
          padding: 24px;
          width: 100%;
          text-align: center;

          &:nth-child(2) {
            margin: 0 24px;
          }

          .title {
            font-size: $space-medium;
            text-align: center;
          }

          .price {
            font-size: 14px;
            color: $asbestos;

            .dollars {
              font-weight: bold;
              font-size: $space-large;
              color: black;
            }
          }
        }

        .active {
          background-color: #e8f5f3;
        }
      }
    }

    .submit-button {
      @include ardu-store-button($teal3, $white);

      margin-left: $space-large;
      margin-top: $layout-spacing-lg;

      @include breakpoint-below($breakpoint-580) {
        margin-left: 0;
      }

      .busy {
        align-self: center;
        height: 18px;
        position: absolute;
        right: 22px;
        top: 8px;
        visibility: hidden;
        width: 18px;

        > div {
          @include spinner($silver, $white, 35px);
        }
      }

      .disabled {
        opacity: 0.5 !important;
        cursor: default;
      }
    }

    .cancel-button {
      @include ardu-store-button($white, $teal3);

      margin-left: $space-large;
      margin-top: $layout-spacing-lg;

      @include breakpoint-below($breakpoint-580) {
        margin-left: 0;
      }
    }
  }

  .organization {
    display: flex;
    background-color: white;
    border: 1px solid $smoke;
    border-radius: 3px;
    width: 100%;
    padding: 20px;
    align-items: center;
    margin-top: 16px;
    justify-content: space-between;

    &:hover {
      border: 1px solid #000;
      cursor: pointer;
    }

    &--selected {
      border: 1px solid #3fae98;
    }

    &--disabled {
      &:hover {
        border: 1px solid $smoke;
        cursor: unset;
      }
    }

    .organization-text {
      display: flex;
      flex-direction: column;
      margin-left: 8px;

      .organization-sub-text {
        color: gray;
        margin-top: 12px;
      }
    }

    .organization-box {
      display: flex;
      align-items: center;
    }
  }

  .add-new-organization {
    background-color: white;
    border: 1px solid $smoke;
    padding: 20px;
    border-radius: 3px;
    margin-top: 16px;
    width: 100%;
    font-size: 16px;
    line-height: 170%;
    display: flex;

    &:hover {
      border: 1px solid #000;
      cursor: pointer;
    }

    &::before {
      color: #000;
      content: "\002B";
      font-size: 28px;
      margin-right: 8px;
    }
  }

  .new-organization-wrapper {
    padding: 20px;
    background-color: white;
    border: 1px solid $smoke;
    border-radius: 3px;
    margin-top: 16px;
    width: 100%;

    &:hover {
      border: 1px solid;
      cursor: pointer;
    }

    &.selected {
      border: 1px solid #3fae98;
    }

    .new-organization-title {
      margin-bottom: 12px;
    }
  }

  .institutions-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
}
