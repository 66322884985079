@import "~arduino-sass/src/variables";
@import "~arduino-sass/src/typography";
@import "~arduino-sass/src/buttons";

.app {
  text-align: center;

  .button {
    @include ardu-button($teal3, $teal5);
  }
}
