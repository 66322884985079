@import "~arduino-sass/src/buttons";
@import "~arduino-sass/src/variables";
@import "~arduino-sass/src/notifications";
@import "../../../../styles/variables";
@import "../../../../styles/mixins";

#root .alternative-payment {
  text-align: left;

  h6 {
    font-weight: 700;
    font-size: 16px;
    margin: 0 0 24px;
  }

  &__body {
    display: flex;
    font-size: 14px;
    margin-bottom: 24px;
    align-items: center;
  }

  &__credit-card {
    display: flex;
    font-size: 14px;
    align-items: center;

    &--button {
      margin-left: 16px;
      color: $teal3 !important;
      font-weight: 700 !important;
    }
  }

  &__icon {
    margin-right: 12px;
  }

  &__text {
    flex: 0 0 80%;
    line-height: 170%;
  }

  .hr {
    margin: 24px 0;
  }

  .terms-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
    min-height: 46px;
    width: 100%;

    .checkbox-wrapper {
      display: flex;
      flex-direction: column;

      .terms-link {
        text-transform: unset;
      }

      .error-message {
        @include font(opensans, regular);

        color: $notifyerror;
        font-size: 16px;
        letter-spacing: 0.7px;
        padding-top: 5px;
      }
    }
  }
}
