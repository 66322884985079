@import "~arduino-sass/src/mixins";
@import "~arduino-sass/src/variables";
@import "../../../styles/variables";
@import "../../../styles/mixins";

#root {
  .breadcrumbs {
    font-size: 16px;
    height: $layout-spacing-sm;
    letter-spacing: 0.7px;
    margin-bottom: 48px;
    margin-top: 0;

    @include breakpoint-below($breakpoint-580) {
      height: 50px !important;
    }

    ul {
      display: flex;
      margin: 0;
      flex-wrap: wrap;
    }

    .active {
      @include font(opensans, regular);

      color: $teal3 !important;
      font-weight: bold !important;
    }

    li {
      margin-right: $layout-spacing-xs;
    }

    .pointer {
      cursor: pointer;

      &:hover {
        color: $teal3;
      }
    }
  }
}
